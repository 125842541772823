import * as React from "react";

function SvgCancelBoldVector(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.643 6.999l5.012-5A1.17 1.17 0 0012 .342L7 5.355 2 .343A1.17 1.17 0 10.345 1.998L5.357 7l-5.012 5a1.166 1.166 0 000 1.656 1.165 1.165 0 001.655 0l5-5.012 5 5.012a1.164 1.164 0 001.655 0 1.166 1.166 0 000-1.655L8.643 6.999z"
        fill="#5F6D7C"
      />
    </svg>
  );
}

export default SvgCancelBoldVector;
