import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';

export default function Popup(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.active)
  }, [props.active])

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
      setOpen(false);
    }
    return
  };

  return (
    <div>
      <Dialog
        className={props.className}
        fullWidth
        fullScreen
        open={open}
        onClose={handleClose}
      >
        {props.children}
      </Dialog>
    </div>
  )
}
