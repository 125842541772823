export const GET_HEADER_PAGES = 'GET_HEADER_PAGES'
export const GET_CATEGORIES_DATA = 'GET_CATEGORIES_DATA'
export const GET_DISCOUNTED_PRODUCT_DATA = 'GET_DISCOUNTED_PRODUCT_DATA'
export const GET_FEATURED_PRODUCT_DATA = 'GET_FEATURED_PRODUCT_DATA'
export const GET_PRODUCT_ID = 'GET_PRODUCT_ID'
export const GET_SLUG_PRODUCT_DATA = 'GET_SLUG_PRODUCT_DATA'
export const GET_SLUG_PRODUCT_FILTERS = 'GET_SLUG_PRODUCT_FILTERS'
export const GET_OFFERED_PRODUCT = 'GET_OFFERED_PRODUCT'
export const GET_NEWEST_PRODUCT = 'GET_NEWEST_PRODUCT'
export const GET_WISHLIST = 'GET_WISHLIST'
export const ADD_TO_WISHLIST = 'GET_TO_WISHLIST'
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS'
export const USER_AUTH = 'USER_AUTH'
export const USER_AUTH_GOOGLE = 'USER_AUTH_GOOGLE'
export const USER_REGISTER = 'USER_REGISTER'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_PASS_FORGOT = 'USER_PASS_FORGOT'
export const USER_PASS_RESET = 'USER_PASS_RESET'
export const AUTHORIZE = 'AUTHORIZE'
export const USER_LOGOUT_LOCAL = 'USER_LOGOUT_LOCAL'
export const SET_DEFAULT_USER_DETAILS = 'SET_DEFAULT_USER_DETAILS'
export const REMOVE_USER_ADDRESS = 'REMOVE_USER_ADDRESS'
export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS'
export const CREATE_USER_ADDRESS = 'CREATE_USER_ADDRESS'
export const GET_ORDERS = 'GET_ORDERS'

export const INFO_BADGES = 'INFO_BADGES'
export const HOME_SLIDER_DATA = 'HOME_SLIDER_DATA'
export const BRANDS_DATA = 'BRANDS_DATA'
export const MANUS_DATA = 'MANUS_DATA'

export const BECOME_PARTNER = "BECOME_PARTNER"
export const PRODUCT_REQUEST = "PRODUCT_REQUEST"

export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const CHANGE_USER_DETAILS = 'CHANGE_USER_DETAILS'
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD'

export const BANNER_DATA = 'BANNER_DATA'

export const SET_LOADING = 'SET_LOADING'
export const SET_LOCALE = 'SET_LOCALE'

export const GET_REGIONS = 'GET_REGIONS'
export const GET_CITIES_BY_REGIONS = 'GET_CITIES_BY_REGIONS'
export const GET_ADDRESS_BY_CITY = 'GET_ADDRESS_BY_CITY'
export const GET_POST_BRANCH_BY_ADDRESS = 'GET_POST_BRANCH_BY_ADDRESS'
export const GET_POST_BRANCH_BY_INDEX = 'GET_POST_BRANCH_BY_INDEX'
export const RESET_ALL_BRANCHES = 'RESET_ALL_BRANCHES'
export const SET_POST_BRANCH = 'SET_POST_BRANCH'
export const SET_DEFAULT_POST_BRANCH = 'SET_DEFAULT_POST_BRANCH'
export const DELETE_POST_BRANCH = 'DELETE_POST_BRANCH'
export const EDIT_POST_BRANCH = 'EDIT_POST_BRANCH'
export const ADD_NEW_CARD = 'ADD_NEW_CARD'
export const CARD_SUCCESS = 'CARD_SUCCESS'
export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export  const FILTER_ORDERS = "FILTER_ORDERS"

export const ADD_CART = 'ADD_CART'
export const GET_CART = 'GET_CART'
export const CHECK_PRODUCT_IN_CART = "CHECK_PRODUCT_IN_CART"
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM'
export const EMPTY_CART = 'EMPTY_CART'
export const EDIT_CART_ITEM_QTY = 'EDIT_CART_ITEM_QTY'
export const EDIT_CART_ITEM = 'EDIT_CART_ITEM'
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE'
export const CANCEL_PROMO_CODE = 'CANCEL_PROMO_CODE'
export const CHECKOUT = 'CHECKOUT'
export const SEND_EMAIL_VALIDATION = 'SEND_EMAIL_VALIDATION'
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'

export const GET_CATEGORY_BANNERS = 'GET_CATEGORY_BANNERS'

export const SEARCH_PRODUCT = 'SEARCH_PRODUCT'
export const SEARCH_PRODUCT_FILTERS = 'SEARCH_PRODUCT_FILTERS'

export const GET_FEATURED_CATEGORIES_DATA = 'GET_FEATURED_CATEGORIES_DATA'

export const GET_TRANSLATION_LIST = 'GET_TRANSLATION_LIST'

export const GET_FOOTER_DATA = 'GET_FOOTER_DATA'

export const CHANGE_USER_LANG = 'CHANGE_USER_LANG'
export const TRACK_ORDER = 'TRACK_ORDER'

export const RESET_POST_BRANCHES = 'RESET_POST_BRANCHES'

export const GET_PAGE = 'GET_PAGE'

export const GET_RELATED_PRODUCT_DATA = 'GET_RELATED_PRODUCT_DATA'

export const RESET_PASSWORD = "RESET_PASSWORD"

export const POST_CONTACTS = "POST_CONTACTS";

export const GET_CATEGORIES_EXTRA_DATA = "GET_CATEGORIES_EXTRA_DATA";

export const GET_VERIFY_DATA = "GET_VERIFY_DATA";

export const AUTH_GOOGLE_LOGIN = "AUTH_GOOGLE_LOGIN"
export const AUTH_FB_LOGIN = "AUTH_GOOGLE_LOGIN"
