import React from 'react';
import {FormHelperText} from "@material-ui/core";
import Input from "react-phone-number-input"
import 'react-phone-number-input/style.css';


const PhoneNumber = ({phone, setPhone, error, setTouched}) => {

    return (
        <>
            <Input
                defaultCountry="AM"
                placeholder='Phone number'
                value={phone}
                name="phone_number"
                id="phone_number"
                onChange={setPhone}
                limitMaxLength
                onBlur={() => setTouched && setTouched('phone_number')}
            />
            {error &&
            <FormHelperText
                error={true}
                >
                <span>{error}</span>
            </FormHelperText>}
        </>

    );
}

export default PhoneNumber;
