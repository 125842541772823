import * as React from "react";

function SvgLoginSmall(props) {
  return (
  <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1104_10)">
      <path d="M10.22 4.06465e-05C6.747 -0.00995935 3.69 1.82604 1.944 4.59804C1.866 4.72104 1.953 4.88404 2.096 4.88404H3.698C3.807 4.88404 3.909 4.83404 3.978 4.74904C4.138 4.55104 4.308 4.36004 4.488 4.17904C5.22063 3.42923 6.09261 2.82968 7.055 2.41404C8.04671 1.98573 9.11576 1.76552 10.196 1.76704C11.287 1.76704 12.345 1.98404 13.338 2.41404C14.3004 2.82968 15.1724 3.42923 15.905 4.17904C16.6445 4.93193 17.2321 5.82008 17.636 6.79504C18.0569 7.81096 18.2717 8.90039 18.268 10C18.2704 11.0997 18.0552 12.1889 17.635 13.205C17.232 14.1805 16.6442 15.0687 15.904 15.821C15.1714 16.5709 14.2994 17.1704 13.337 17.586C12.345 18.0145 11.2756 18.2347 10.195 18.233C9.1151 18.2344 8.0464 18.0142 7.055 17.586C6.09227 17.1705 5.21994 16.5709 4.487 15.821C4.30913 15.6382 4.13899 15.4481 3.977 15.251C3.94338 15.209 3.90078 15.1751 3.85232 15.1518C3.80387 15.1284 3.7508 15.1162 3.697 15.116H2.095C2.06194 15.1166 2.02962 15.126 2.00141 15.1432C1.97321 15.1605 1.95014 15.185 1.93462 15.2142C1.9191 15.2434 1.91169 15.2762 1.91317 15.3093C1.91464 15.3423 1.92494 15.3743 1.943 15.402C3.687 18.167 6.73 20 10.195 20C15.575 20 19.945 15.58 20 10.102C20.053 4.53704 15.678 0.0140406 10.22 4.06465e-05ZM7.335 12.605V10.837H0.182003C0.133286 10.8363 0.0868472 10.8163 0.0527712 10.7815C0.0186951 10.7466 -0.000266924 10.6998 2.8394e-06 10.651V9.35004C2.8394e-06 9.24804 0.0820028 9.16404 0.182003 9.16404H7.335V7.39504C7.335 7.24004 7.513 7.15104 7.631 7.24904L10.863 9.85404C10.885 9.87148 10.9027 9.89368 10.9148 9.91898C10.927 9.94427 10.9332 9.97199 10.933 10C10.9328 10.028 10.9264 10.0556 10.9143 10.0809C10.9022 10.1061 10.8847 10.1284 10.863 10.146L7.631 12.751C7.513 12.847 7.335 12.761 7.335 12.605Z" fill="#004494"/>
    </g>
    <defs>
      <clipPath id="clip0_1104_10">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);
}

export default SvgLoginSmall;
