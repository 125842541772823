import {
    BRANDS_DATA,
    HOME_SLIDER_DATA,
    MANUS_DATA,
    GET_TRANSLATION_LIST,
    GET_FOOTER_DATA,
    GET_HEADER_PAGES,
    BECOME_PARTNER, PRODUCT_REQUEST
} from './types';
import { API_URL } from '../../constants'

export const getHeaderPages = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_HEADER_PAGES,
        meta: {
            config,
            url: `${API_URL}/api/header-pages`,
        },
    }
}

export const getSliderData = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: HOME_SLIDER_DATA,
        meta: {
            config,
            url: `${API_URL}/api/sliders`,
        },
    }
}

export const getBrandsData = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: BRANDS_DATA,
        meta: {
            config,
            url: `${API_URL}/api/brands`,
        },
    }
}

export const getMenuData = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: MANUS_DATA,
        meta: {
            config,
            url: `${API_URL}/api/menus`,
        },
    }
}

export const getTranslationList = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_TRANSLATION_LIST,
        meta: {
            config,
            url: `${API_URL}/api/get-translation-list`,
        },
    }
}

export const getFooterData = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_FOOTER_DATA,
        meta: {
            config,
            url: `${API_URL}/api/footer-pages`,
        },
    }
}
export const becomePartner = (data) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: data
    }
    return {
        type: BECOME_PARTNER,
        meta: {
            config,
            url: `${API_URL}/api/become-the-partner`,
        },
    }
}
export const productRequest = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
        formData.append(key, data[key])
    })
    const config = {
        method: "POST",
        body: {
            file: true,
            data: formData
        }
    }
    return {
        type: PRODUCT_REQUEST,
        meta: {
            config,
            url: `${API_URL}/api/product-request`,
        },
    }
}
