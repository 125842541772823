import * as React from "react";

function SvgHeart(props) {
  return (
    <svg
      width={34}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="heart_svg__a" fill="#fff">
        <path d="M34 10.49c0-9.004-10.348-13.506-17-8.253-7.391-5.253-17-.75-17 8.254S14.783 30 17 30c2.217 0 17-10.505 17-19.51z" />
      </mask>
      <path
        d="M34 10.49c0-9.004-10.348-13.506-17-8.253-7.391-5.253-17-.75-17 8.254S14.783 30 17 30c2.217 0 17-10.505 17-19.51z"
        fill={props.color}
      />
      <path
        d="M17 2.237l-1.159 1.63 1.222.868 1.176-.929L17 2.237zM17 30v-2 2zm1.24-26.194C23.65-.466 32 3.248 32 10.491h4C36-.275 23.653-5.565 15.76.667l2.48 3.14zM32 10.491c0 1.723-.719 3.692-2.036 5.78-1.304 2.066-3.098 4.095-5.006 5.897-1.903 1.797-3.869 3.32-5.471 4.383-.804.533-1.488.93-2.006 1.187-.26.129-.451.208-.575.25-.16.056-.1.012.094.012v4c.47 0 .907-.126 1.207-.229.336-.116.69-.271 1.047-.447.714-.354 1.548-.846 2.443-1.44 1.793-1.188 3.938-2.854 6.008-4.808 2.065-1.95 4.105-4.235 5.642-6.67C34.871 15.99 36 13.268 36 10.49h-4zM17 28c.193 0 .254.044.094-.011a5.161 5.161 0 01-.575-.25c-.518-.257-1.202-.655-2.006-1.188-1.602-1.062-3.568-2.586-5.471-4.383-1.908-1.802-3.702-3.831-5.006-5.898C2.719 14.183 2 12.214 2 10.49h-4c0 2.78 1.13 5.5 2.653 7.915 1.537 2.436 3.577 4.721 5.642 6.671 2.07 1.954 4.215 3.62 6.008 4.809.894.593 1.729 1.086 2.443 1.439.356.176.71.331 1.047.447.3.103.736.229 1.207.229v-4zM2 10.49c0-3.725 1.954-6.404 4.593-7.67 2.656-1.273 6.133-1.167 9.248 1.047L18.16.607c-4.276-3.039-9.3-3.309-13.295-1.393C.85 1.138-2 5.212-2 10.491h4z"
        fill="#0063BD"
        mask="url(#heart_svg__a)"
      />
    </svg>
  );
}

export default SvgHeart;
