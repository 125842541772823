import React from 'react';
import { useWindowSize } from "@reach/window-size";
import BannerIpad from '../header/headerIpad/BannerIpad';
import HeaderContactIpad from '../header/headerIpad/HeadercontactIpad';
import BannerInfo from './banner-info';
import HeaderContact from './header-contact';

export default function Header() {
    const { width } = useWindowSize();
    
    return (
        <header className="header">
            {/*{width <= 1024 ? <BannerIpad /> : <BannerInfo />}*/}
            {width <= 1200  ?  <HeaderContactIpad /> : <HeaderContact />}
        </header>
    )
}

