import * as React from "react";

function SvgRemove(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.235 28.235L1.765 1.765M28.235 1.765l-26.47 26.47"
        stroke="#5F6D7C"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRemove;
