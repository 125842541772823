import * as React from "react";

function SvgLogin(props) {
  return (
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_75_575)">
          <path d="M15.36 0.98394C10.15 0.96994 5.56605 3.72294 2.94505 7.88094C2.82905 8.06594 2.95905 8.30994 3.17405 8.30994H5.57605C5.65685 8.31009 5.73663 8.29189 5.80938 8.25673C5.88214 8.22156 5.94596 8.17035 5.99605 8.10694C6.23662 7.80938 6.49229 7.52433 6.76205 7.25294C7.86081 6.12811 9.1686 5.22862 10.612 4.60494C12.102 3.96294 13.687 3.63494 15.324 3.63494C16.961 3.63494 18.546 3.95994 20.036 4.60494C21.4795 5.22858 22.7873 6.12807 23.886 7.25294C24.9957 8.3822 25.8777 9.71441 26.484 11.1769C27.116 12.6979 27.434 14.3129 27.434 15.9839C27.434 17.6549 27.112 19.2699 26.484 20.7909C25.8793 22.2541 24.9975 23.5865 23.887 24.7149C22.7881 25.84 21.4799 26.7395 20.036 27.3629C18.5483 28.0052 16.9445 28.3354 15.324 28.3329C13.7035 28.3355 12.0998 28.0053 10.612 27.3629C9.16858 26.7393 7.86077 25.8398 6.76205 24.7149C6.49205 24.4399 6.23905 24.1539 5.99605 23.8609C5.94561 23.798 5.8817 23.7472 5.80901 23.7122C5.73632 23.6772 5.65671 23.659 5.57605 23.6589H3.17305C3.12334 23.6595 3.07471 23.6735 3.03222 23.6993C2.98973 23.725 2.95493 23.7618 2.93144 23.8056C2.90795 23.8494 2.89664 23.8987 2.89868 23.9483C2.90072 23.998 2.91604 24.0462 2.94305 24.0879C5.56105 28.2349 10.126 30.9839 15.323 30.9839C23.393 30.9839 29.947 24.3529 30.029 16.1379C30.111 7.78994 23.547 1.00494 15.361 0.98394H15.36ZM11.032 19.8909V17.2399H0.303046C0.229754 17.2389 0.159868 17.2088 0.108697 17.1563C0.0575269 17.1039 0.0292444 17.0332 0.0300459 16.9599V15.0069C0.0300459 14.8539 0.153046 14.7279 0.303046 14.7279H11.033V12.0779C11.033 11.8439 11.299 11.7109 11.476 11.8579L16.325 15.7639C16.3581 15.7902 16.3848 15.8237 16.4029 15.8618C16.4211 15.8999 16.4304 15.9417 16.43 15.9839C16.43 16.0261 16.4205 16.0677 16.4024 16.1058C16.3842 16.1438 16.3578 16.1774 16.325 16.2039L11.476 20.1109C11.299 20.2539 11.032 20.1249 11.032 19.8909Z" fill="#004494"/>
        </g>
        <defs>
          <clipPath id="clip0_75_575">
            <rect width="30" height="31" fill="white" transform="translate(0.0300293)"/>
          </clipPath>
        </defs>
      </svg>

  );
}

export default SvgLogin;
