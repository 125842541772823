import * as React from "react";

function SvgPayCard(props) {
  return (
    <svg
      width={208}
      height={148}
      fill="none"
      viewBox="0 0 208 148"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={1} fill="#004494">
        <path d="M196.761 0H10.439a10.413 10.413 0 00-7.377 3.085A10.573 10.573 0 000 10.518v126.964a10.572 10.572 0 003.062 7.433A10.413 10.413 0 0010.439 148H196.76a10.414 10.414 0 007.378-3.085 10.576 10.576 0 003.061-7.433V10.518a10.577 10.577 0 00-3.061-7.433A10.414 10.414 0 00196.761 0zM8.515 51.478v-20.02h19.869v20.02H8.515zm28.384 0v-20.02h2.838v20.02h-2.838zm11.353 0v-20.02h2.838v20.02h-2.838zm11.353 0v-20.02h2.839v20.02h-2.839zm-51.09 8.58h190.17v77.424a1.946 1.946 0 01-.565 1.369c-.36.364-.849.568-1.359.569H10.439a1.917 1.917 0 01-1.36-.569 1.949 1.949 0 01-.564-1.369V60.058zm190.17-28.6v20.02H70.959v-20.02h127.726zM10.439 8.58H196.76c.51 0 .999.205 1.359.569.361.363.564.855.565 1.369v12.361H8.515V10.518a1.95 1.95 0 01.565-1.37 1.92 1.92 0 011.359-.568z" />
        <path d="M41.16 125.121a18.54 18.54 0 008.516-2.095 18.54 18.54 0 008.514 2.095 18.38 18.38 0 0013.046-5.445 18.66 18.66 0 005.404-13.144c0-4.931-1.944-9.659-5.404-13.145a18.38 18.38 0 00-13.046-5.445 18.546 18.546 0 00-8.514 2.095 18.546 18.546 0 00-8.516-2.095 18.379 18.379 0 00-13.045 5.445 18.66 18.66 0 00-5.404 13.145 18.66 18.66 0 005.404 13.144 18.38 18.38 0 0013.045 5.445zm13.732-28.007c1.054-.4 2.172-.6 3.298-.592a9.896 9.896 0 017.025 2.932 10.049 10.049 0 012.91 7.078c0 2.654-1.047 5.2-2.91 7.078a9.896 9.896 0 01-7.025 2.931 9.16 9.16 0 01-3.353-.614 9.038 9.038 0 01-4.114-2.871 9.85 9.85 0 01-2.467-6.554 9.85 9.85 0 012.506-6.54 8.965 8.965 0 014.075-2.826l.055-.022zm-13.732-.592a9.39 9.39 0 011.453.106 18.498 18.498 0 00-2.872 9.904c0 3.509.997 6.946 2.872 9.903a9.896 9.896 0 01-8.477-2.825 10.051 10.051 0 01-2.91-7.078 10.05 10.05 0 012.91-7.078 9.896 9.896 0 017.024-2.932zM154.691 99.38h22.707a4.242 4.242 0 003-1.261 4.307 4.307 0 001.242-3.028 4.307 4.307 0 00-1.242-3.028 4.242 4.242 0 00-3-1.262h-22.707a4.242 4.242 0 00-3 1.262 4.307 4.307 0 00-1.242 3.028c0 1.135.447 2.224 1.242 3.028a4.242 4.242 0 003 1.262zM120.625 99.38h22.707a4.232 4.232 0 003.02-1.25 4.294 4.294 0 001.253-3.04 4.317 4.317 0 00-1.253-3.038 4.235 4.235 0 00-3.02-1.25h-22.707a4.242 4.242 0 00-3.001 1.261 4.306 4.306 0 00-1.241 3.028c0 1.135.446 2.224 1.241 3.028a4.242 4.242 0 003.001 1.262zM120.625 122.262h34.06a4.243 4.243 0 003.021-1.251 4.294 4.294 0 001.252-3.039 4.312 4.312 0 00-1.252-3.039 4.26 4.26 0 00-1.387-.929 4.224 4.224 0 00-1.634-.322h-34.06a4.241 4.241 0 00-3.001 1.262 4.307 4.307 0 00-1.241 3.028c0 1.135.446 2.224 1.241 3.028a4.241 4.241 0 003.001 1.262z" />
      </g>
    </svg>
  );
}

export default SvgPayCard;
