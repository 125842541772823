import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from "@material-ui/core/styles";
import { Formik } from "formik";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Cookies from 'js-cookie'
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import PassVisible from '../../../resources/icons/Passvisible';
import PopUp from '../../ui/popup/index';
import { userAuth, userRegister } from '../../../redux/actions/authData';
import { setLoading } from '../../../redux/actions/loading';
import CancelVector from '../../../resources/icons/CancelVector'
import { userPassForgot } from '../../../redux/actions/authData'
import { getCart } from '../../../redux/actions/cartData';
import { getUserData, getWishlist } from '../../../redux/actions/userData';
import './style.scss';
import GoogleLogo from '../../../resources/icons/Google';
import FacebookLogo from '../../../resources/FacebookLogo';
import HaypostLogoPNG from '../../../assets/images/haypostLogo.png';
import {API_URL} from "../../../constants"
import * as Yup from "yup";
import toast from "react-hot-toast";


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default function LoginRegistrationTab(props) {
  const coockiesData = useSelector(state => state.authData.userAuth?.access_token);
  const loading = useSelector(state => state.loading.loading)
  const translations = useSelector(state => state.infoData.translations)
  const locale = useSelector(state => state.language.locale)
  const theme = useTheme();
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [checkedLogin, setCheckedLogin] = useState(true);
  const [checkedReg, setCheckedReg] = useState(true);
  const [passVisible, setPassVisible] = useState(false)
  const [activePopUp, setActivePopUp] = useState(false)
  const [activePopUpContent, setActivePopUpContent] = useState('email');

  const ValidateSchemaEmail = Yup.object().shape({
    email: Yup.string()
        .email(translations["ERR_INVALID_EMAIL"][locale])
        .required(translations["REQUIRED"][locale]),
  });

  const ValidateSchemaLogin = Yup.object().shape({
    password: Yup.string()
        .min(8, translations["ERR_PASSWORD_MUST_BE"][locale])
        .required(translations["ERR_ENTER_YOUR_PASSWORD"][locale]),
    email: Yup.string()
        .email(translations["ERR_INVALID_EMAIL"][locale])
        .required(translations["REQUIRED"][locale]),
  });

  const ValidateSchemaReg = Yup.object().shape({
    last_name: Yup.string()
        .min(3, translations["ERR_TOO_SHORT"][locale])
        .required(translations["REQUIRED"][locale]),
    first_name: Yup.string()
        .min(2,translations["ERR_TOO_SHORT"][locale])
        .max(50, translations["ERR_TOO_LONG"][locale])
        .required(translations["REQUIRED"][locale]),
    phone_number: Yup.string()
        .required(translations["REQUIRED"][locale])
        .test('len', translations["ERR_PHONE_NUMBER_MUST_BE"][locale], val => val?.length === 8),
    email: Yup.string()
        .email(translations["ERR_INVALID_COMMON"][locale])
        .required(translations["REQUIRED"][locale]),
    password: Yup
        .string()
        .min(8, translations["ERR_PASSWORD_MUST_BE"][locale])
        .required(translations["REQUIRED"][locale]),
    password_confirmation:Yup
        .string()
        .oneOf([Yup.ref('password'), null], translations["ERR_PASSWORDS_MUST_MAtCH"][locale]),
  });


  const handleChangeLoginRadioValue = (event) => {
    setCheckedLogin(event.target.checked);
  };

  const handleChangeRegRadioValue = (event) => {
    setCheckedReg(event.target.checked);
  };

  useEffect(() => {
    if (Cookies.get('authorized') || coockiesData) {
      setActivePopUp(false)
    }
  }, [coockiesData])

  const userPassForgothandler = (props) => {
    activePopUpHandler(props)
  }

  const activePopUpHandler = (content) => {
    setActivePopUpContent(content)
    setActivePopUp(true)
  }
  const pasivePopUpHandler = () => {
    setActivePopUp(false)
  }

  const changePassVisibleHandler = () => {
    setPassVisible(prev => !prev)
  }

  const handleChangeBtn = (event, newValue) => {
    setValue(newValue);
  };


  const a11yProps = useCallback((index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  }, []);


  const activeContent = useMemo(() => {
    return activePopUpContent === 'email' ? (
      <div className={`forogot-email-popup-area + ${loading ? 'loader' : ''}`}>
        <CancelVector onClick={pasivePopUpHandler} className='popup-cancale-icon' />
        <p className='content-title'>{translations['FORGOT_PASSWORD_QUESTION'][locale]}</p>
        <div className='email-popup-underline' />
        <p className='info-title'>{translations['FORGOT_PASS_FILL_MESSAGE'][locale]}</p>
        <Formik
          initialValues={{ email: '', }}
          validationSchema={ValidateSchemaEmail}
          onSubmit={(values, { setSubmitting }) => {
            const formData = { ...values, };
            dispatch(setLoading(true))
            dispatch(userPassForgot(formData))
              .then(res => {
                if (res) {
                  userPassForgothandler('THinfo')
                  dispatch(setLoading(false))
                }
                props.onClosePopup && props.onClosePopup()
              })
              .catch(() => {
                setSubmitting(false)
                dispatch(setLoading(false))
              })
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={`email-area + ${errors.email && touched.email ? 'invalid-input' : ''}`}>
                <TextField
                  placeholder={translations['EMAIL'][locale]}
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email} />
              </div>
              <button className='registr-btn' type='submit'>{translations['SEND'][locale]}</button>
            </form>
          )}
        </Formik>
      </div>) : (<div className='th-email-popup-area'>
        <CancelVector onClick={pasivePopUpHandler} className='popup-cancale-icon' />
        <p className='content-title'>{translations['THANK_YOU'][locale]}</p>
        <div className='email-popup-underline' />
        <p className='info-title'>{translations['CHECK_EMAIL_MESSAGE'][locale]}</p>
      </div>)
  }, [activePopUpContent])

  return (
    <div className={`login-reg-tab-area + ${loading ? 'loader' : ''}`} style={{ position: props.active ? 'absolute' : 'inherit' }}>
    <div className='login-haypost-logo'>
      <img src={HaypostLogoPNG}/>
    </div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeBtn}>
          <Tab label={translations['LOGIN'][locale]} {...a11yProps(0)} />
          <Tab label={translations['REGISTER'][locale]} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <div className="login-input-area">
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Formik
            initialValues={{
              password: '',
              email: '',
            }}
            validationSchema={ValidateSchemaLogin}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              const formData = {
                ...values,
                guest_cart_token: props.guestCart ? props.guestCart : ""
              };
              dispatch(setLoading(true))
              dispatch(userAuth(formData))
                .then((res) => {
                  console.log(res)

                  if (res && res.access_token) {
                    Cookies.set('authorized',
                      res.access_token,
                      {
                        expires: 0.5
                      })
                    pasivePopUpHandler()
                    dispatch(getCart({}))
                    dispatch(setLoading(false))
                    dispatch(getUserData())
                    dispatch(getWishlist())
                  }
                  else {
                    dispatch(setLoading(false))
                  }
                  props.onClosePopup && props.onClosePopup()
                })
                .catch((e) => {
                  setSubmitting(false)
                  dispatch(setLoading(false))
                  resetForm()
                  toast.error(e.message)
                  if(e.not_verified){
                    props.setVerifymessagePopUp(e.message);
                    props.onClosePopup()
                  }
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='input-area'>
                  <div className={`email-area + ${errors.email && touched.email ? 'invalid-input' : ''}`}>
                    <TextField
                      placeholder={translations['EMAIL'][locale]}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email} />
                  </div>
                  <div className={`pass-area + ${errors.password && touched.password ? 'invalid-input' : ''}`}>
                    <TextField
                      name='password'
                      placeholder={translations['PASSWORD'][locale]}
                      type={passVisible ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password} />
                    <PassVisible onClick={changePassVisibleHandler} className='visible-icon' />
                  </div>
                  <div className='remember-pass'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedLogin}
                          onChange={handleChangeLoginRadioValue}
                          name="checkedB"
                          color="primary" />
                      }
                      label={translations['REMEMBER_PASS'][locale]} />
                  </div>
                  <button
                    className={`login-btn + ${Object.values(errors).length > 0  ? 'disabled-btn' : ''}`}
                    disabled={isSubmitting && checkedLogin}
                    type='submit' >
                    {translations['LOGIN'][locale]}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              password: '',
              password_confirmation: '',
              agree: true,
            }}
            validationSchema={ValidateSchemaReg}
            onSubmit={(values, { setSubmitting }) => {
              const formData = {
                ...values,
                phone_number: "374"+values.phone_number
              };
              dispatch(setLoading(true))
              dispatch(userRegister(formData))
                .then(res => {

                  if (res.message === "User successfully registered") {
                    dispatch(getCart({}))
                    dispatch(setLoading(false))
                    dispatch(getUserData())
                    props.setRegisterSuccessPopUp(true)
                  }
                  props.onClosePopup && props.onClosePopup()

                })
                .catch(() => {
                  setSubmitting(false)
                  dispatch(setLoading(false))
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='input-area' >
                  <div className='user-name-surename-area'>
                    <div className={`user-name + ${errors.first_name && touched.first_name ? 'invalid-input' : ''}`}>
                      <TextField
                        placeholder={translations['FIRST_NAME'][locale]}
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        autoComplete="off"
                      />
                    </div>
                    <div className={`user-surname + ${errors.last_name && touched.last_name ? 'invalid-input' : ''}`}>
                      <TextField
                        placeholder={translations['LAST_NAME'][locale]}
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className={`email-area + ${errors.email && touched.email ? 'invalid-input' : ''}`}>
                    <TextField
                      placeholder={translations['EMAIL'][locale]}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="off"
                    />
                  </div>
                  <div className={`phone-number-area + ${errors.phone_number && touched.phone_number ? 'invalid-input' : ''}`}>
                    <TextField
                      placeholder={translations['PHONE_NUMBER'][locale]}
                      name="phone_number"
                      onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                      onBlur={handleBlur}
                      value={values.phone_number}
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                      autoComplete="off"
                    />
                      <span className='reg-phone-number-locale'>(+374)</span>
                  </div>
                  <div className={`pass-area + ${errors.password && touched.password ? 'invalid-input' : ''}`}>
                    <TextField
                      name='password'
                      placeholder={translations['PASSWORD'][locale]}
                      type={passVisible ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      autoComplete="off"
                    />
                    <PassVisible onClick={changePassVisibleHandler} className='visible-icon' />
                  </div>
                  <div className={`pass-area + ${errors.password_confirmation && touched.password_confirmation ? 'invalid-input' : ''}`}>
                    <TextField
                      name='password_confirmation'
                      placeholder={translations['REPEAT_PASSWORD'][locale]}
                      type={passVisible ? "text" : "password"}
                      value={values.password_confirmation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password_confirmation && Boolean(errors.password_confirmation)}
                      helperText={touched.password_confirmation && errors.password_confirmation}
                      autoComplete="off"
                    />
                    <PassVisible onClick={changePassVisibleHandler} className='visible-icon' />
                  </div>
                  <div className='remember-pass'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedReg}
                          onChange={handleChangeRegRadioValue}
                          name="checkedG"
                          color="primary" />
                      }
                      label={translations['TERMS_AGREED'][locale]} />
                  </div>
                  <button
                    className={`registr-btn + ${!Object.values(values).every(Boolean) || Object.values(errors).length > 0 || !checkedReg ? 'disabled-btn' : ''}`}
                    disabled={!checkedReg}
                    type='submit'
                  >{translations['REGISTER'][locale]}</button>
                </div>
              </form>
            )}
          </Formik>
        </TabPanel>
      </div>
      <div className='login-underline-area'>
        <div className='login-underline' />
        <div className='login-underline-title'>{translations['OR'][locale]}</div>
        <div className='login-underline' />
      </div>
      <div className='logn-social-area'>
        <div className='google-area'
          onClick={() => window.location.href = `${API_URL}/api/auth/google/login`}
        >
          <GoogleLogo />
          <span>Google</span>
        </div>
        <div className='fb-area'
          onClick={() => window.location.href = `${API_URL}/api/auth/facebook/login`}
        >
          <FacebookLogo />
          <span>facebook</span>
        </div>
      </div>
      {!value && <div className='forgot-pass-area'>
        <a className='forgot-pass' onClick={() => activePopUpHandler('email')}>{translations['FORGOT_PASSWORD_QUESTION'][locale]}</a>
      </div>}
      <PopUp onClose={pasivePopUpHandler} active={activePopUp}>
        <div className={`email-send-popup-cancale-icona + ${loading ? 'loader' : ''}`} >
          {activeContent}
        </div>
      </PopUp>
    </div>
  )
}
