import * as React from "react";

function SvgEmailIcon(props) {
  return (
    <svg
      width={20}
      height={16}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.25a1 1 0 011-1h18a1 1 0 011 1v13.5a1 1 0 01-1 1H1a1 1 0 01-1-1V1.25zm2 1v11.5h16V2.25H2z"
        fill="#9098B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.24.6A1 1 0 011.65.49L10 7.648 18.35.491a1 1 0 011.3 1.518l-9 7.714a1 1 0 01-1.3 0l-9-7.714A1 1 0 01.24.6z"
        fill="#9098B1"
      />
    </svg>
  );
}

export default SvgEmailIcon;
