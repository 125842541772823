import * as React from "react";

function SvgVisaCard(props) {
  return (
    <svg
      width={93}
      height={31}
      viewBox="0 0 93 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.495.278c-6.501 0-12.315 3.386-12.315 9.634 0 7.168 10.307 7.665 10.307 11.262 0 1.519-1.733 2.871-4.686 2.871-4.2 0-7.336-1.895-7.336-1.895l-1.348 6.312s3.613 1.6 8.409 1.6c7.116 0 12.71-3.55 12.71-9.909 0-7.573-10.353-8.051-10.353-11.4 0-1.187 1.421-2.494 4.374-2.494 3.329 0 6.043 1.38 6.043 1.38l1.311-6.091c0 .01-2.952-1.27-7.116-1.27zM.98.738l-.156.92s2.733.506 5.2 1.51c3.173 1.15 3.402 1.821 3.934 3.9l5.823 22.526h7.804L35.616.738h-7.785L20.1 20.356 16.945 3.729c-.285-1.905-1.752-2.99-3.55-2.99H.98zm37.773 0l-6.108 28.846h7.428L46.153.738h-7.4zm41.422 0c-1.789 0-2.742.966-3.44 2.641L65.86 29.585h7.785l1.504-4.371h9.492l.917 4.37h6.877L86.438.738h-6.263zm1.018 7.794l2.31 10.83h-6.19l3.88-10.83z"
        fill="url(#visaCard_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="visaCard_svg__paint0_linear"
          x1={0.825}
          y1={15.169}
          x2={92.433}
          y2={15.169}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20225F" />
          <stop offset={0.2} stopColor="#1A1F61" />
          <stop offset={0.41} stopColor="#172272" />
          <stop offset={0.595} stopColor="#152682" />
          <stop offset={0.802} stopColor="#12288E" />
          <stop offset={1} stopColor="#0E2C9A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgVisaCard;
