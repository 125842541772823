import * as React from "react";

function SvgHomeVector(props) {
  return (
    <svg
      width={25}
      height={27}
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.466 0a1.044 1.044 0 00-.61.224l-9.87 7.751A5.19 5.19 0 000 12.054v13.215C0 26.213.79 27 1.736 27h6.945c.947 0 1.736-.787 1.736-1.73v-6.924c0-.204.142-.346.347-.346h3.472c.205 0 .347.142.347.346v6.923c0 .944.79 1.731 1.736 1.731h6.945C24.21 27 25 26.213 25 25.27V12.053a5.19 5.19 0 00-1.986-4.079L13.144.224A1.044 1.044 0 0012.466 0zm.034 2.361l9.226 7.246a3.108 3.108 0 011.19 2.447v12.87h-6.25v-6.578c0-1.325-1.1-2.423-2.43-2.423h-3.472c-1.33 0-2.43 1.098-2.43 2.423v6.577h-6.25v-12.87c0-.955.437-1.854 1.19-2.446L12.5 2.361z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgHomeVector;
