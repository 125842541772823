import React from 'react'
import {useDispatch} from 'react-redux';
import CancelVector from "../../../resources/icons/CancelVector";
import {Formik} from "formik";
import {setLoading} from "../../../redux/actions/loading";
import TextField from "@material-ui/core/TextField";
import "./style.scss"
import {becomePartner} from "../../../redux/actions/infoData";
import * as Yup from "yup";

function BecomeAPartner ({loading, translations, pasivePopUpHandler, locale,}) {
    const dispatch = useDispatch();
    const partnerValidation = Yup.object().shape({
        company_name: Yup.string()
            .required(translations["REQUIRED"][locale]),
        contact_person: Yup.string()
            .required(translations["REQUIRED"][locale]),
        phone_number: Yup.string()
            .required(translations["ERR_PHONE_NUMBER"][locale])
            .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, translations["ERR_PHONE_NUMBER"][locale])
            .min(8, translations["ERR_PHONE_NUMBER_MUST_BE"][locale])
            .max(8, translations["ERR_TOO_LONG"][locale]),
        email: Yup.string()
            .required(translations["REQUIRED"][locale])
            .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, translations["ERR_EMAIL"][locale]),
    });

    return (
        <div className={`email-send-popup-cancale-icona + ${loading ? 'loader' : ''}`}>
            <div className={`forogot-email-popup-area partner-area ${loading ? 'loader' : ''}`}>
                <CancelVector onClick={pasivePopUpHandler} className='popup-cancale-icon'/>
                <p className='content-title'>{translations["BECOME_A_PARTNER"][locale]}</p>
                <div className='email-popup-underline'/>
                <Formik
                    initialValues={{
                        email: '',
                        company_name: '',
                        contact_person: '',
                        phone_number: ''
                    }}
                    validationSchema={partnerValidation}
                    onSubmit={(values, {setSubmitting}) => {
                        const formData = {...values};
                        dispatch(setLoading(true))
                        dispatch(becomePartner(formData))
                            .then(res => {
                                if (res) {
                                    dispatch(setLoading(false))
                                    pasivePopUpHandler()
                                }
                            })
                            .catch(() => {
                                dispatch(setLoading(false))
                                pasivePopUpHandler()
                            })
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                className={`company-name ${errors.company_name && touched.company_name ? 'invalid-input' : ''}`}>
                                <TextField
                                    placeholder={translations["COMPANY_NAME"][locale]}
                                    autoComplete="off"
                                    name="company_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company_name}
                                    error={touched.company_name && Boolean(errors.company_name)}
                                    helperText={touched.company_name && errors.company_name}/>
                            </div>
                            <div
                                className={`user-name + ${errors.contact_person && touched.contact_person ? 'invalid-input' : ''}`}>
                                <TextField
                                    placeholder={translations["CONTACT_PERSON"][locale]}
                                    autoComplete="off"
                                    name="contact_person"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.contact_person}
                                    error={touched.contact_person && Boolean(errors.contact_person)}
                                    helperText={touched.contact_person && errors.contact_person}/>
                            </div>
                            <div
                                className={`phone-number-area + ${errors.phone_number && touched.phone_number ? 'invalid-input' : ''}`}>
                                <TextField
                                    placeholder={translations['PHONE_NUMBER'][locale]}
                                    autoComplete="off"
                                    name="phone_number"
                                    onChange={(e) => !isNaN(e.target.value) && handleChange(e)}
                                    onBlur={handleBlur}
                                    value={values.phone_number}
                                    error={touched.phone_number && Boolean(errors.phone_number)}
                                    helperText={touched.phone_number && errors.phone_number}/>
                                <span className='reg-phone-number-locale'>(+374)</span>
                            </div>
                            <div
                                className={`email-area + ${errors.email && touched.email ? 'invalid-input' : ''}`}>
                                <TextField
                                    placeholder={translations['EMAIL'][locale]}
                                    autoComplete="off"
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}/>
                            </div>
                            <button
                                disabled={Object.keys(errors).length}
                                className='registr-btn'
                                type='submit'>{translations['SEND'][locale]}</button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default BecomeAPartner