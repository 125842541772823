import * as React from "react";

function SvgAddress(props) {
  return (
    <svg
      width={80}
      height={99}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72 35.676a32.466 32.466 0 01-2.931 13.505l-3.66 5.6-25.776 39.447c-1.181 1.808-3.829 1.813-5.017.009L4.946 49.18A32.564 32.564 0 012 35.676C2 17.077 17.675 2 37.007 2c4.596 0 8.984.852 13.004 2.401m1.063 32.834A14.098 14.098 0 0137 51.359a14.102 14.102 0 01-14.067-14.124A14.089 14.089 0 0137 23.125c7.77 0 14.074 6.326 14.074 14.11z"
        stroke="#C9C9C9"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.024 2v29M49 16.254h29"
        stroke="#5F6D7C"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgAddress;
