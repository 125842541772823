import React from 'react';

function svgMyOrdersIpadSmall(props) {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.689165 0.689165C2.10369e-07 1.37832 0 2.48751 0 4.70588V15.2941C0 17.5125 2.10369e-07 18.6216 0.689165 19.3108C1.37832 20 2.48751 20 4.70588 20H11.7647C13.9831 20 15.0922 20 15.7814 19.3108C16.4706 18.6216 16.4706 17.5125 16.4706 15.2941V4.70588C16.4706 2.48751 16.4706 1.37832 15.7814 0.689165C15.0922 2.10369e-07 13.9831 0 11.7647 0H4.70588C2.48751 0 1.37832 2.10369e-07 0.689165 0.689165ZM4.70588 4.70588C4.05614 4.70588 3.52941 5.23261 3.52941 5.88235C3.52941 6.53209 4.05614 7.05882 4.70588 7.05882H11.7647C12.4145 7.05882 12.9412 6.53209 12.9412 5.88235C12.9412 5.23261 12.4145 4.70588 11.7647 4.70588H4.70588ZM4.70588 9.41177C4.05614 9.41177 3.52941 9.93847 3.52941 10.5882C3.52941 11.238 4.05614 11.7647 4.70588 11.7647H11.7647C12.4145 11.7647 12.9412 11.238 12.9412 10.5882C12.9412 9.93847 12.4145 9.41177 11.7647 9.41177H4.70588ZM4.70588 14.1176C4.05614 14.1176 3.52941 14.6444 3.52941 15.2941C3.52941 15.9439 4.05614 16.4706 4.70588 16.4706H9.41177C10.0615 16.4706 10.5882 15.9439 10.5882 15.2941C10.5882 14.6444 10.0615 14.1176 9.41177 14.1176H4.70588Z" fill="#0063BD"/>
        </svg>

    );
}

export default svgMyOrdersIpadSmall;