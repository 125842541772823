import * as React from "react";

function SvgDel(props) {
  return (
    <svg
      width={24}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.81 22c6.522 0 11.81-4.925 11.81-11S18.331 0 11.81 0C5.287 0 0 4.925 0 11s5.287 11 11.81 11z"
        fill="#F5F5F5"
      />
      <path
        d="M16.625 7.707L15.865 7l-8.35 7.778.759.707 8.35-7.778z"
        fill="#5F6D7C"
      />
      <path
        d="M15.866 15.485l.759-.707L8.275 7l-.76.707 8.35 7.778z"
        fill="#5F6D7C"
      />
    </svg>
  );
}

export default SvgDel;
