// import * as React from "react";

// function SvgPaymentCash(props) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width="178" height="102" viewBox="0 0 178 102" fill="none">
//       <g opacity="0.2">
//         <path d="M91.6745 14.5088C88.1459 10.6816 84.3188 8.40161 78.9717 8.40161H51.8288H21.9717V59.973H32.5574C32.5574 59.973 39.1259 70.8302 51.5574 70.8302C73.2717 70.8302 106.115 70.8302 106.115 70.8302V32.8302L91.6745 14.5088Z" stroke="#004494" strokeWidth="5.51613" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M174.003 32.8569H68.1462L57.2891 43.7141V97.9998H174.003V32.8569Z" fill="white" stroke="#004494" strokeWidth="6.30415" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M155.006 87.1426H76.2913C76.2913 82.6368 72.6542 78.9997 68.1484 78.9997V51.8568C72.6542 51.8568 76.2913 48.2197 76.2913 43.714H155.006C155.006 48.2197 158.643 51.8568 163.148 51.8568V78.9997C158.643 78.9997 155.006 82.6368 155.006 87.1426Z" stroke="#004494" strokeWidth="6.30415" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M149.577 65.4283H144.148" stroke="#004494" strokeWidth="6.30415" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M38.3965 43.7686C38.3965 43.7686 49.4979 52.9429 63.0965 46.8357C63.0965 46.8357 75.3651 57.8014 79.0836 60.4886C87.9594 66.4871 103.377 59.2943 97.0794 52.9971C90.7551 46.6728 66.7065 22.0814 66.7065 22.0814" fill="white" />
//         <path d="M38.3965 43.7686C38.3965 43.7686 49.4979 52.9429 63.0965 46.8357C63.0965 46.8357 75.3651 57.8014 79.0836 60.4886C87.9594 66.4871 103.377 59.2943 97.0794 52.9971C90.7551 46.6728 66.7065 22.0814 66.7065 22.0814" stroke="#004494" strokeWidth="1.46154" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M66.7053 22.0814C66.7053 22.0814 90.7539 46.6728 97.051 52.97C103.348 59.2671 87.931 66.46 79.0553 60.4614C75.3367 57.7743 63.0682 46.8086 63.0682 46.8086C49.4696 52.8886 38.3682 43.7414 38.3682 43.7414" stroke="#004494" strokeWidth="4.38461" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M3 3H22V68.1429H3" stroke="#004494" strokeWidth="4.72811" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M13.8594 11.1429V19.2858" stroke="#004494" strokeWidth="2.92308" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
//         <path d="M116.532 67.4539H120.449V65.6709H116.532V63.157H120.449V62.3386C120.449 59.6298 119.095 58.2755 116.386 58.2755C114.983 58.2755 113.96 58.6847 113.317 59.5032C112.674 60.3021 112.352 61.6078 112.352 63.4201H108.23C108.23 60.8283 108.913 58.7627 110.277 57.2232C111.641 55.6837 113.706 54.9139 116.474 54.9139C119.104 54.9139 121.111 55.6155 122.495 57.0186C123.879 58.4216 124.57 60.2924 124.57 62.6309V63.157H128.487V65.6709H124.57V67.4539H128.487V69.997H124.57V76.077H120.449V69.997H116.532V67.4539Z" fill="#004494" />
//       </g>
//     </svg>
//   );
// }

// export default SvgPaymentCash;


import * as React from "react";

function SvgPaymentCash(props) {

  return (
    <svg
      width={225}
      height={128}
      fill="none"
      viewBox="0 0 225 128"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M115.528 17.605c-4.478-4.857-9.335-7.75-16.12-7.75H27.073v65.444h13.433s8.336 13.778 24.112 13.778h69.233V40.855l-18.324-23.25z"
        stroke="#004494"
        strokeWidth={7}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M220.006 40.889H85.672L71.894 54.666v68.889h148.112V40.889z"
        fill="#fff"
        stroke="#004494"
        strokeWidth={8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.898 109.777H96.009a10.32 10.32 0 00-10.333-10.333V65a10.32 10.32 0 0010.333-10.334h99.889A10.32 10.32 0 00206.231 65v34.444a10.32 10.32 0 00-10.333 10.333zM189.006 82.222h-6.889"
        stroke="#004494"
        strokeWidth={8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.918 54.736s14.088 11.642 31.344 3.892c0 0 15.57 13.915 20.288 17.325 11.264 7.612 30.828-1.515 22.837-9.506-8.026-8.026-38.543-39.233-38.543-39.233"
        fill="#fff"
      />
      <path
        d="M47.918 54.736s14.088 11.642 31.344 3.892c0 0 15.57 13.915 20.288 17.325 11.264 7.612 30.828-1.515 22.837-9.506-8.026-8.026-38.543-39.233-38.543-39.233"
        stroke="#004494"
        strokeWidth={1.855}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.843 27.214s30.518 31.207 38.509 39.198-11.574 17.119-22.837 9.507c-4.719-3.41-20.288-17.326-20.288-17.326-17.256 7.716-31.344-3.892-31.344-3.892"
        stroke="#004494"
        strokeWidth={5.564}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3h24.111v82.667H3"
        stroke="#004494"
        strokeWidth={6}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.781 13.334v10.333"
        stroke="#004494"
        strokeWidth={3.709}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPaymentCash;
