import * as React from "react";

function SvgPassvisible(props) {
  return (
    <svg
      width={24}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.328 8.898c.63-.931 1.9-2.625 3.62-4.075C7.678 3.362 9.746 2.25 12 2.25c2.254 0 4.321 1.113 6.052 2.572 1.72 1.451 2.99 3.144 3.62 4.076a.176.176 0 010 .204c-.63.931-1.9 2.625-3.62 4.075-1.73 1.46-3.798 2.573-6.052 2.573-2.254 0-4.321-1.113-6.052-2.572-1.721-1.451-2.991-3.143-3.62-4.076a.176.176 0 010-.204zM12 0C8.969 0 6.384 1.488 4.452 3.117 2.509 4.753 1.106 6.635.417 7.65a2.395 2.395 0 000 2.697c.689 1.017 2.092 2.898 4.035 4.536C6.382 16.512 8.969 18 12 18c3.031 0 5.616-1.488 7.548-3.117 1.943-1.636 3.346-3.517 4.035-4.534a2.395 2.395 0 000-2.697c-.689-1.018-2.092-2.899-4.035-4.535C17.618 1.488 15.031 0 12 0zm0 12c.812 0 1.59-.316 2.164-.879A2.97 2.97 0 0015.06 9a2.97 2.97 0 00-.896-2.121A3.092 3.092 0 0012 6c-.812 0-1.59.316-2.164.879A2.97 2.97 0 008.94 9c0 .796.322 1.559.896 2.121A3.092 3.092 0 0012 12z"
        fill="#C9C9C9"
      />
    </svg>
  );
}

export default SvgPassvisible;
