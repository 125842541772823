import * as React from "react";

function SvgPersonalPage(props) {
  return (
      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.892 0.0844727C8.46301 0.0844727 0.817505 7.72847 0.817505 17.1582C0.817505 26.588 8.46226 34.232 17.892 34.232C27.3218 34.232 34.9665 26.588 34.9665 17.1582C34.9665 7.72847 27.3225 0.0844727 17.892 0.0844727ZM17.892 5.18972C21.0113 5.18972 23.5395 7.71872 23.5395 10.8372C23.5395 13.9565 21.0113 16.4847 17.892 16.4847C14.7735 16.4847 12.2453 13.9565 12.2453 10.8372C12.2453 7.71872 14.7735 5.18972 17.892 5.18972ZM17.8883 29.768C14.7765 29.768 11.9265 28.6347 9.72825 26.759C9.19275 26.3022 8.88375 25.6332 8.88375 24.929C8.88375 21.767 11.4435 19.2357 14.607 19.2357H21.1793C24.3428 19.2357 26.8935 21.767 26.8935 24.929C26.8935 25.6332 26.5853 26.3015 26.049 26.7582C23.8515 28.6347 21.0008 29.768 17.8883 29.768Z" fill="#0063BD"/>
      </svg>
  );
}

export default SvgPersonalPage;
