import * as React from "react";

function SvgResultDone(props) {
  return (
    <svg
      width={112}
      height={112}
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.556 0a55.555 55.555 0 100 111.11 55.555 55.555 0 000-111.11zM91.84 36.91L46.215 82.5 19.271 55.556a4.91 4.91 0 116.944-6.945l20.07 20.07 38.68-38.646a4.909 4.909 0 018.01 1.593 4.91 4.91 0 01-1.065 5.351l-.07-.07z"
        fill="#71BD8F"
      />
    </svg>
  );
}

export default SvgResultDone;
