import CancelBoldVector from "../../../resources/icons/CancelBoldVector";
import {Formik} from "formik";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import PhoneNumber from "./PhoneNumber";
import {useDispatch} from "react-redux";
import {productRequest} from "../../../redux/actions/infoData";


export default function ProductRequest({translations, locale, setReqPopUp}) {
    const dispatch = useDispatch();


    const validation = Yup.object().shape({
        full_name: Yup.string()
            .min(2, 'Too Short!')
            .max(30, 'Too Long!')
            .required('Required'),
        email: Yup.string().email("Invalid email").required("Required"),
        phone_number: Yup.string()
            .required(),
        product_name: Yup.string()
            .required(),
        product_url: Yup.string()
            .required(),
        quantity: Yup.string()
            .required()
            .matches(`^[0-9]`, "enter only numbers"),
        file: Yup.string()
            .required("Please chose a image.")
    });


    return (
        <div
            className="product-request"
        >
            <p>{translations["PRODUCT_REQUEST"][locale]}</p>
            <div
                onClick={() => {
                    setReqPopUp(false)
                }}
                className="close-icon"
            >
                <CancelBoldVector/>
            </div>
            <Formik
                initialValues={{
                    full_name: "",
                    phone_number: "+374",
                    email: '',
                    product_url: '',
                    product_name: '',
                    quantity: '',
                    file: ''
                }}
                validationSchema={validation}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(productRequest({
                        ...values,
                        phone_number: values.phone_number.split("+")[1]
                    }))
                        .then(()=>{
                            setReqPopUp('')
                        })
                }}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div
                            className={`textField-area + ${errors.full_name && touched.full_name ? 'invalid-input' : ''}`}>
                            <TextField
                                placeholder={translations["FIRST_NAME"][locale] + " " + translations["LAST_NAME"][locale] + "*"}
                                type="full_name"
                                name="full_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.full_name}
                                error={touched.full_name && Boolean(errors.full_name)}
                                helperText={touched.full_name && errors.full_name}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            className={`textField-area phone_number ${errors.phone_number && touched.phone_number ? 'invalid-input' : ''}`}>
                            <PhoneNumber
                                phone={values.phone_number}
                                setPhone={(v) => {
                                    values.phone_number = v
                                }}
                                error={errors.phone_number}
                            />
                        </div>
                        <div className={`textField-area + ${errors.email && touched.email ? 'invalid-input' : ''}`}>
                            <TextField
                                placeholder={translations['EMAIL'][locale] + "*"}
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            className={`textField-area + ${errors.product_url && touched.product_url ? 'invalid-input' : ''}`}>
                            <TextField
                                placeholder={translations['PRODUCT_LINK'][locale]}
                                type="text"
                                name="product_url"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.product_url}
                                error={touched.product_url && Boolean(errors.product_url)}
                                helperText={touched.product_url && errors.product_url}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            className={`textField-area + ${errors.product_name && touched.product_name ? 'invalid-input' : ''}`}>
                            <TextField
                                placeholder={translations['PRODUCT_NAME'][locale] + "*"}
                                type="text"
                                name="product_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.product_name}
                                error={touched.product_name && Boolean(errors.product_name)}
                                helperText={touched.product_name && errors.product_name}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            className={`textField-area  qtt ${errors.quantity && touched.quantity ? 'invalid-input' : ''}`}>
                            <TextField
                                placeholder={translations['QUANTITY'][locale]}
                                type="text"
                                name="quantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quantity}
                                error={touched.quantity && Boolean(errors.quantity)}
                                helperText={touched.quantity && errors.quantity}
                                autoComplete="off"
                            />
                        </div>
                        <div className="file">
                            <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={(e) => {
                                    values.file = e.target.files[0];
                                    delete errors.file
                                }}
                                autoComplete="off"
                            />
                            {touched.file && !values.file && <span className="img-err">{errors.file}</span>}
                        </div>
                        <button className='registr-btn' type='submit'>{translations['SEND'][locale]}</button>
                    </form>
                )}
            </Formik>
        </div>
    )
}