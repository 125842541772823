import * as React from "react";

function SvgBorgerMenu(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={30} height={30} rx={2.4} fill="#0063BD" />
      <path
        d="M6 9h18M6 15h18M6 21h18"
        stroke="#fff"
        strokeWidth={1.8}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgBorgerMenu;
