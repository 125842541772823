import {
    GET_CATEGORIES_DATA,
    GET_DISCOUNTED_PRODUCT_DATA,
    GET_FEATURED_PRODUCT_DATA,
    GET_NEWEST_PRODUCT,
    GET_OFFERED_PRODUCT,
    GET_PRODUCT_ID,
    GET_SLUG_PRODUCT_DATA,
    GET_SLUG_PRODUCT_FILTERS,
    GET_PRODUCT_DETAILS,
    GET_CATEGORY_BANNERS,
    SEARCH_PRODUCT,
    SEARCH_PRODUCT_FILTERS,
    GET_FEATURED_CATEGORIES_DATA,
    GET_RELATED_PRODUCT_DATA,
    GET_CATEGORIES_EXTRA_DATA
} from "../actions/types";

const initialState = {
    categories: [],
    categoriesExtraData : {},
    featuredCategories: [],
    discounted: [],
    featured: [],
    productID: [],
    products: [],
    neweset: [],
    offered: [],
    filters: [],
    product: {},
    related:{},
    banner: null,
    adBanner: null,
};


export default function (state = initialState, { type, payload }) {

    switch (type) {
        case GET_CATEGORIES_DATA:
            return {
                ...state,
                categories: payload.data
            };
        case GET_CATEGORIES_EXTRA_DATA:
            return {
                ...state,
                categoriesExtraData : payload
            };
        case GET_DISCOUNTED_PRODUCT_DATA:
            return {
                ...state,
                discounted: payload.data
            };
        case GET_FEATURED_PRODUCT_DATA:
            return {
                ...state,
                featured: payload.data
            };
        case GET_PRODUCT_ID:
            return {
                ...state,
                productID: payload.data
            };
        case GET_SLUG_PRODUCT_DATA:
        case SEARCH_PRODUCT:
            return {
                ...state,
                products: payload
            };
        case GET_SLUG_PRODUCT_FILTERS:
        case SEARCH_PRODUCT_FILTERS:
            return {
                ...state,
                filters: payload
            }
        case GET_NEWEST_PRODUCT:
            return {
                ...state,
                neweset: payload.data
            };
        case GET_OFFERED_PRODUCT:
            return {
                ...state,
                offered: payload.data
            };
        case GET_PRODUCT_DETAILS:
            return {
                ...state,
                product: payload
            }
        case GET_CATEGORY_BANNERS:
            return {
                ...state,
                banner: payload.data.banner,
                adBanner: payload.data.advertising_banner,
            }
        case GET_FEATURED_CATEGORIES_DATA:
            return {
                ...state,
                featuredCategories:payload.data
            }
        case GET_RELATED_PRODUCT_DATA:
            return {
                ...state,
                related:payload.data
            }
        default:
            return state;
    }
}