import * as React from "react";

function SvgRemoveIpadSmall(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.824 18.824L1.177 1.177M18.824 1.177L1.177 18.824"
        stroke="#5F6D7C"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRemoveIpadSmall;
