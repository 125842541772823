import {
    GET_CATEGORIES_DATA,
    GET_DISCOUNTED_PRODUCT_DATA,
    GET_FEATURED_PRODUCT_DATA,
    GET_NEWEST_PRODUCT,
    GET_OFFERED_PRODUCT,
    GET_PRODUCT_ID,
    GET_SLUG_PRODUCT_DATA,
    GET_SLUG_PRODUCT_FILTERS,
    GET_PRODUCT_DETAILS,
    GET_CATEGORY_BANNERS,
    SEARCH_PRODUCT,
    SEARCH_PRODUCT_FILTERS,
    GET_FEATURED_CATEGORIES_DATA,
    GET_RELATED_PRODUCT_DATA,
    GET_CATEGORIES_EXTRA_DATA
} from './types';
import { API_URL } from '../../constants'


export const getCategories = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_CATEGORIES_DATA,
        meta: {
            config,
            url: `${API_URL}/api/categories`,
        },
    }
}

export const getFeaturedCategories = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_FEATURED_CATEGORIES_DATA,
        meta: {
            config,
            url: `${API_URL}/api/get-featured-categories`,
        },
    }
}

export const getFeaturedProducts = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_FEATURED_PRODUCT_DATA,
        meta: {
            config,
            url: `${API_URL}/api/products/featured`,
        },
    }
}

export const getRelatedProducts = (slug) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_RELATED_PRODUCT_DATA,
        meta: {
            config,
            url: `${API_URL}/api/products/related?slug=${slug}`,
        },
    }
}

export const getProductSlug = (slug, page, filters) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: filters
    }
    return {
        type: GET_SLUG_PRODUCT_DATA,
        meta: {
            config,
            url: `${API_URL}/api/categories/${slug}/products?page=${page || 1}`,
        },
    }
}

export const getProductSlugFilters = (slug) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_SLUG_PRODUCT_FILTERS,
        meta: {
            config,
            url: `${API_URL}/api/categories/${slug}/filters`,
        },
    }
}

export const getNewestProduct = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_NEWEST_PRODUCT,
        meta: {
            config,
            url: `${API_URL}/api/products/newest`,
        },
    }
}

export const getOfferedProduct = () => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_OFFERED_PRODUCT,
        meta: {
            config,
            url: `${API_URL}/api/products/offered`,
        },
    }
}


export const getProductDetails = (slug) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_PRODUCT_DETAILS,
        meta: {
            config,
            url: `${API_URL}/api/products/${slug}`,
        },
    }
}



export const getCategoryBanners = (slug) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_CATEGORY_BANNERS,
        meta: {
            config,
            url: `${API_URL}/api/categories/${slug}/banners`,
        },
    }
}


export const searchProduct = (query, page, filters) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: filters
    }
    return {
        type: SEARCH_PRODUCT,
        meta: {
            config,
            url: `${API_URL}/api/search?query=${query}&page=${page || 1}`,
        },
    }
}

export const searchProductFilters = (query) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: SEARCH_PRODUCT_FILTERS,
        meta: {
            config,
            url: `${API_URL}/api/search/filters?query=${query}`,
        },
    }
}


export const getCategoriesExtraData = (slug) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_CATEGORIES_EXTRA_DATA,
        meta: {
            config,
            url: `${API_URL}/api/categories/${slug}`,
        },
    }
}