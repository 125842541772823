import * as React from "react";

function SvgGroup(props) {
  return (
    <svg
      width={26}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.28 22.36l-8.077-2.779c.235-.308.473-.626.712-.953 2.942-4.026 4.435-7.148 4.435-9.28C22.35 4.193 18.156 0 13.002 0 7.847 0 3.654 4.193 3.654 9.348c0 2.132 1.492 5.254 4.435 9.28.24.33.48.65.717.961L.724 22.36a.777.777 0 000 1.47l12.004 4.129a.778.778 0 00.505 0l12.046-4.129a.777.777 0 00.001-1.47zM20.796 9.348c0 3.365-5.18 9.954-7.794 12.93-2.615-2.975-7.795-9.563-7.795-12.93 0-4.298 3.497-7.794 7.795-7.794 4.297 0 7.794 3.496 7.794 7.794zM12.98 26.402l-9.613-3.307 6.45-2.21a66.216 66.216 0 002.61 3.081.777.777 0 001.147 0 66.381 66.381 0 002.617-3.089l6.443 2.216-9.654 3.309z"
        fill={props.fill}
      />
      <path
        d="M13 12.886a3.997 3.997 0 003.994-3.993A3.998 3.998 0 0013 4.9a3.998 3.998 0 00-3.993 3.993A3.998 3.998 0 0013 12.886zm2.44-3.993a2.442 2.442 0 01-2.44 2.44 2.442 2.442 0 01-2.438-2.44A2.442 2.442 0 0113 6.453a2.442 2.442 0 012.44 2.44z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgGroup;
