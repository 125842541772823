import * as React from "react";

function SvgPrinterIcon(props) {
  return (
    <svg
      width={25}
      height={23}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.688 2.487c0-.65.246-1.272.686-1.731A2.295 2.295 0 017.031.039H17.97c.621 0 1.217.258 1.657.717.44.459.686 1.081.686 1.73v.816h.782c1.036 0 2.03.43 2.762 1.195A4.172 4.172 0 0125 7.38v8.158c0 .649-.247 1.271-.686 1.73a2.295 2.295 0 01-1.658.717h-2.343v2.447c0 .65-.247 1.272-.687 1.73a2.294 2.294 0 01-1.657.717H7.03a2.294 2.294 0 01-1.657-.716 2.503 2.503 0 01-.686-1.73v-2.448H2.344a2.295 2.295 0 01-1.658-.717A2.503 2.503 0 010 15.54V7.38C0 6.3.412 5.261 1.144 4.497a3.824 3.824 0 012.762-1.195h.781v-.815zm1.562.815h12.5v-.815a.834.834 0 00-.229-.577.765.765 0 00-.552-.24H7.03a.765.765 0 00-.552.24.834.834 0 00-.229.577v.815zM4.687 16.354v-2.447c0-.649.247-1.271.687-1.73a2.295 2.295 0 011.657-.717H17.97c.621 0 1.217.258 1.657.717.44.459.686 1.081.686 1.73v2.447h2.344a.765.765 0 00.553-.239.834.834 0 00.229-.576V7.38c0-.649-.247-1.271-.687-1.73a2.294 2.294 0 00-1.657-.717H3.906c-.621 0-1.217.258-1.657.717a2.503 2.503 0 00-.687 1.73v8.158c0 .216.083.424.23.576a.765.765 0 00.552.24h2.344zm2.344-3.263a.765.765 0 00-.552.24.834.834 0 00-.229.576v6.526c0 .216.082.424.229.577a.765.765 0 00.552.239H17.97a.765.765 0 00.552-.239.835.835 0 00.229-.577v-6.526a.834.834 0 00-.229-.577.765.765 0 00-.552-.239H7.03z"
        fill="#5F6D7C"
      />
    </svg>
  );
}

export default SvgPrinterIcon;
