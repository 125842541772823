import { GET_PAGE, } from '../actions/types'

const authState = {
    page: {}
}

export default function (state = authState, { type, payload }) {

    switch (type) {
        case GET_PAGE:
            if (Array.isArray(payload.data.sections)) {
                return {
                    ...state,
                    page: payload.data
                }
            }else{
                const arr = [];
                Object.keys(payload.data.sections).map((el, i)=>{
                    arr.push(payload.data.sections[el])
                })
                return{
                    ...state,
                    page: {
                        ...payload.data,
                        sections: arr
                    }
                }
            }
        default:
            return state;
    }

}