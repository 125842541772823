import * as React from "react";

function SvgWishheart(props) {
  return (
    <svg
      width={34}
      height={34}
      fill="none"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={17} r={17} fill='#fff' />
      <mask id="wishheart_svg__a" fill="#fff">
        <path d="M27 14.945c0-5.103-6.391-7.654-10.5-4.678C11.935 7.292 6 9.842 6 14.945 6 20.047 15.13 26 16.5 26 17.87 26 27 20.047 27 14.945z" />
      </mask>
      <path
        d="M27 14.945c0-5.103-6.391-7.654-10.5-4.678C11.935 7.292 6 9.842 6 14.945 6 20.047 15.13 26 16.5 26 17.87 26 27 20.047 27 14.945z"
        fill={props.checkedwish}
      />
      <path
        d="M16.5 10.268l-.82 1.256.865.563.835-.605-.88-1.214zm.88 1.214c1.579-1.143 3.636-1.247 5.32-.526 1.666.712 2.8 2.146 2.8 3.989h3c0-3.26-2.062-5.653-4.62-6.747-2.537-1.087-5.73-.978-8.26.855l1.76 2.43zm8.12 3.463c0 .844-.388 1.873-1.184 3.03-.782 1.137-1.867 2.266-3.035 3.277a25.638 25.638 0 01-3.342 2.457c-.49.298-.904.519-1.214.66a3.14 3.14 0 01-.333.132c-.091.03-.035-.001.108-.001v3c.314 0 .602-.077.795-.138.217-.068.444-.16.67-.262.45-.205.975-.489 1.533-.828a28.62 28.62 0 003.748-2.753c1.286-1.114 2.57-2.43 3.542-3.844.96-1.395 1.712-3.024 1.712-4.73h-3zm-9 9.555c.143 0 .2.03.108.001a3.14 3.14 0 01-.333-.133c-.31-.14-.724-.361-1.214-.66a25.638 25.638 0 01-3.342-2.456c-1.168-1.011-2.253-2.14-3.035-3.277-.796-1.157-1.184-2.186-1.184-3.03h-3c0 1.706.753 3.335 1.712 4.73.973 1.414 2.256 2.73 3.542 3.845a28.62 28.62 0 003.748 2.752c.558.34 1.082.623 1.534.828.225.102.452.194.669.262.193.061.48.138.795.138v-3zm-9-9.555c0-1.897 1.075-3.306 2.642-3.995 1.588-.699 3.678-.64 5.539.574l1.638-2.513c-2.704-1.763-5.864-1.916-8.385-.807C6.392 9.322 4.5 11.739 4.5 14.944h3z"
        fill="#0063BD"
        mask="url(#wishheart_svg__a)"
      />
    </svg>
  );
}

export default SvgWishheart;
