import Cookies from 'js-cookie';
import {setLocale} from '../../../redux/actions/language';
import {ChangeUserLang} from '../../../redux/actions/userData';

export const isNotNull = (data) => {
    return (data && (!!data.length || !!Object.keys(data).length))
}

export const equals = (arr1, arr2) => {
    if (arr1.length != arr2.length)
        return false;
    else {
        for (var i = 0; i < arr1.length; i++)
            if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i]))
                return false;
        return true;
    }
}


export const displayDate = (date) => {
    let newDate = new Date(date)
    let month = newDate.getMonth() + 1
    let day = newDate.getDate()
    let year = newDate.getFullYear()

    return `${day}.${month}.${year}`
}

export const handleLangChange = (value, dispatch) => {
    localStorage.setItem('lang', value)
    dispatch(setLocale(value))
    if (Cookies.get('authorized')) {
        dispatch(ChangeUserLang())
    }
    window.location.reload();
}

export function displayPrice(price) {
    if (price || price == 0) {
        let val = (price / 1).toFixed(0).replace('.', ' ')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + "֏"
    }
}
