import * as React from "react";

function SvgStepsEllipse3(props) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={props.opacity}>
        <circle cx={18} cy={18} r={18} fill="#0063BD" />
        <path
          d="M20.57 15.344c0 .332-.053.636-.158.909-.105.273-.253.51-.444.71-.187.201-.413.368-.677.5a3.273 3.273 0 01-.882.273v.041c.807.1 1.42.358 1.839.773.42.41.629.945.629 1.606 0 .438-.075.839-.226 1.203-.145.365-.369.68-.67.944-.3.264-.68.47-1.141.615-.46.146-1.005.219-1.634.219-.497 0-.966-.039-1.408-.117a4.19 4.19 0 01-1.238-.423v-1.162c.397.21.825.371 1.286.485.465.114.909.17 1.333.17.419 0 .781-.045 1.087-.136.305-.091.556-.22.752-.39.2-.168.346-.375.437-.622.096-.246.144-.521.144-.827 0-.31-.062-.574-.185-.793a1.438 1.438 0 00-.513-.547 2.395 2.395 0 00-.82-.32 5.212 5.212 0 00-1.087-.103h-.909v-1.033h.91c.368 0 .696-.045.983-.136.288-.091.527-.221.718-.39.196-.169.344-.37.445-.602.1-.232.15-.487.15-.765 0-.237-.041-.45-.123-.636a1.29 1.29 0 00-.349-.472 1.52 1.52 0 00-.54-.3 2.24 2.24 0 00-.697-.103 3.35 3.35 0 00-1.292.233c-.37.15-.722.348-1.06.594l-.628-.854c.173-.142.364-.274.574-.397a4.46 4.46 0 01.697-.321 4.792 4.792 0 011.709-.308c.492 0 .923.062 1.292.185.374.123.686.296.937.52.25.218.44.48.567.785.128.301.191.632.191.992z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgStepsEllipse3;
