import {
    BRANDS_DATA,
    HOME_SLIDER_DATA,
    INFO_BADGES,
    MANUS_DATA,
    GET_TRANSLATION_LIST,
    GET_HEADER_PAGES,
    GET_FOOTER_DATA,
    BECOME_PARTNER, PRODUCT_REQUEST
} from "../actions/types";

const initialState = {
    headerPages: {},
    badges: [],
    sliders: [],
    brands: [],
    manus: [],
    translations: {},
    footer: {},
    becomePartner: {},
};


export default function (state = initialState, {type, payload}) {
    switch (type) {
        case GET_HEADER_PAGES:
            return {
                ...state,
                headerPages: payload
            };
        case INFO_BADGES:
            return {
                ...state,
                badges: payload
            };
        case BECOME_PARTNER:
            return {
                ...state,
                becomePartner: payload
            };
        // case PRODUCT_REQUEST :
        //     return {
        //         ...state,
        //         productReques: payload
        //     }
        case HOME_SLIDER_DATA:
            return {
                ...state,
                sliders: payload
            };
        case BRANDS_DATA:
            return {
                ...state,
                brands: payload.data
            };
        case MANUS_DATA:
            return {
                ...state,
                manus: payload
            };
        case GET_TRANSLATION_LIST:
            return {
                ...state,
                translations: payload
            };
        case GET_FOOTER_DATA:
            return {
                ...state,
                footer: payload

            }
        default:
            return state;
    }
}