import * as React from "react";

function SvgSetting(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.499 8.755A3.75 3.75 0 008.754 12.5a3.75 3.75 0 003.745 3.745 3.75 3.75 0 003.745-3.745 3.75 3.75 0 00-3.745-3.745zm0 6.103A2.36 2.36 0 0110.14 12.5a2.36 2.36 0 012.358-2.358 2.36 2.36 0 012.358 2.358 2.36 2.36 0 01-2.358 2.358z"
        fill={props.fill}
      />
      <path
        d="M24.771 15.273l-2.12-1.915v-1.716l2.12-1.915a.693.693 0 00.176-.78l-1.133-2.736a.693.693 0 00-.676-.427l-2.854.146-1.213-1.214.145-2.853a.693.693 0 00-.427-.677L16.053.053a.694.694 0 00-.78.176l-1.915 2.12h-1.716L9.727.23a.694.694 0 00-.78-.176L6.211 1.186a.693.693 0 00-.427.676l.145 2.854-1.213 1.213-2.854-.145a.693.693 0 00-.676.427L.053 8.947a.694.694 0 00.176.78l2.12 1.915v1.716L.23 15.273a.694.694 0 00-.176.78l1.133 2.736a.692.692 0 00.676.427l2.854-.145 1.213 1.213-.145 2.854a.693.693 0 00.427.676l2.736 1.133a.693.693 0 00.78-.176l1.915-2.12h1.716l1.915 2.12a.694.694 0 00.78.176l2.736-1.133a.693.693 0 00.427-.676l-.145-2.853 1.213-1.214 2.854.145a.694.694 0 00.676-.427l1.133-2.736a.694.694 0 00-.176-.78zm-2.052 2.533l-2.672-.136a.692.692 0 00-.525.202l-1.65 1.65a.694.694 0 00-.202.526l.136 2.672-1.832.759-1.793-1.987a.694.694 0 00-.515-.228h-2.333a.694.694 0 00-.514.228L9.026 23.48l-1.832-.76.136-2.672a.693.693 0 00-.202-.525l-1.65-1.65a.693.693 0 00-.525-.202l-2.673.136-.758-1.832 1.986-1.793a.694.694 0 00.228-.514v-2.333a.693.693 0 00-.228-.515L1.522 9.026l.758-1.832 2.673.137a.692.692 0 00.525-.203l1.65-1.65a.694.694 0 00.202-.525L7.194 2.28l1.832-.758 1.793 1.986a.694.694 0 00.514.229h2.333a.693.693 0 00.515-.23l1.793-1.985 1.832.758-.136 2.673a.694.694 0 00.202.526l1.65 1.65c.138.138.33.213.525.202l2.672-.137.76 1.832-1.987 1.793a.694.694 0 00-.229.515v2.333c0 .196.083.383.23.514l1.985 1.794-.759 1.831z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgSetting;
