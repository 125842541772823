import {
    ADD_CART,
    GET_CART,
    DELETE_CART_ITEM,
    EMPTY_CART,
    EDIT_CART_ITEM_QTY,
    EDIT_CART_ITEM,
    CHECK_PRODUCT_IN_CART,
    SEND_EMAIL_VALIDATION,
    VALIDATE_EMAIL
} from "../actions/types";
import { isNotNull } from "../../shared/components/helpers/utils"
import Cookies from 'js-cookie';

const initialState = {
    cartInfo: {},
    cartItemsCount: 0,
    checkedProducts: []
};


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_CART:
        case DELETE_CART_ITEM:
        case EDIT_CART_ITEM_QTY:
        case EDIT_CART_ITEM:
        case ADD_CART:
            let cart = state.cartInfo
            if (isNotNull(payload) && isNotNull(payload.cartinfo)) {
                if (!Cookies.get('authorized')) {
                    localStorage.setItem('cartToken', payload.cartinfo.token)
                } else {
                    localStorage.setItem('cartToken', '')
                }
                cart = payload.cartinfo
            } else {
                cart = {}
            }
            return {
                ...state,
                cartInfo: cart,
                cartItemsCount: cart && cart.items ? cart.items.length : 0
            };
        case EMPTY_CART: {
            return {
                cartInfo: {},
                cartItemsCount: 0
            }
        };
        case CHECK_PRODUCT_IN_CART: {
            return {
                ...state,
                checkedProducts: payload
            }
        }
        default:
            return state;
    }
}
