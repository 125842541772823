import { SET_LOCALE } from "../actions/types";

const initialState = {
    locale: localStorage.getItem('lang') || 'hy',
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_LOCALE:
            return {
                locale: payload
            };
            
        default:
            return state;
    }
}