import * as React from "react";

function SvgSearchIpadSmall(props) {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.7201 2.47465e-08C9.15748 -0.000112263 10.5664 0.381911 11.7881 1.10306C13.0099 1.82421 13.996 2.85585 14.6355 4.08185C15.275 5.30784 15.5424 6.6795 15.4078 8.04242C15.2731 9.40533 14.7416 10.7054 13.8731 11.7962L19.6951 17.34C19.8803 17.5194 19.988 17.7586 19.9967 18.0101C20.0053 18.2617 19.9144 18.5071 19.742 18.6977C19.5695 18.8883 19.3281 19.0103 19.0656 19.0393C18.8031 19.0683 18.5388 19.0022 18.3251 18.8543L18.2061 18.7571L12.3851 13.2133C11.4089 13.9175 10.2708 14.3904 9.06474 14.593C7.85872 14.7956 6.61941 14.722 5.44914 14.3783C4.27888 14.0347 3.21126 13.4308 2.33444 12.6166C1.45762 11.8023 0.796777 10.8011 0.406479 9.69563C0.0161807 8.59014 -0.0923703 7.41207 0.0897914 6.25873C0.271953 5.10539 0.7396 4.00986 1.45411 3.06264C2.16862 2.11542 3.10949 1.34367 4.19902 0.811141C5.28854 0.278611 6.49546 0.00057595 7.7201 2.47465e-08ZM7.7201 2.00571C6.2309 2.00571 4.80271 2.56912 3.74969 3.572C2.69667 4.57487 2.1051 5.93506 2.1051 7.35333C2.1051 8.77161 2.69667 10.1318 3.74969 11.1347C4.80271 12.1375 6.2309 12.701 7.7201 12.701C9.20929 12.701 10.6375 12.1375 11.6905 11.1347C12.7435 10.1318 13.3351 8.77161 13.3351 7.35333C13.3351 5.93506 12.7435 4.57487 11.6905 3.572C10.6375 2.56912 9.20929 2.00571 7.7201 2.00571Z" fill="#0063BD"/>
      </svg>
  );
}

export default SvgSearchIpadSmall;
