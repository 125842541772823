import * as React from "react";

function SvgTracking(props) {
  return (
    <svg
      width={25}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.885 19.996H4.63V4.518a.814.814 0 00-.814-.814H1.115a.814.814 0 000 1.628h1.887V20.81c0 .45.365.814.814.814h1.078A2.476 2.476 0 007.196 25a2.476 2.476 0 002.301-3.376h8.317A2.476 2.476 0 0020.116 25a2.476 2.476 0 002.302-3.376h1.467a.814.814 0 000-1.628zm-16.69 3.376a.846.846 0 010-1.69.846.846 0 010 1.69zm12.921 0a.846.846 0 010-1.69.846.846 0 010 1.69z"
        fill={props.fill}
      />
      <path
        d="M23.885 0H8.121a.814.814 0 00-.814.814v16.079c0 .45.364.814.814.814h15.764c.45 0 .814-.364.814-.814V.813A.814.814 0 0023.885 0zm-.814 16.08H8.934V9.666h14.137v6.412zm0-8.04H8.934V1.628h14.137V8.04z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgTracking;
