import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import Store from './redux/store'
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <Provider store={Store}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);
