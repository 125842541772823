import * as React from "react";

function SvgPersonalPageIpadSmall(props) {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.47773 0 0 4.47705 0 10C0 15.523 4.47729 20 10 20C15.5227 20 20 15.523 20 10C20 4.47705 15.5231 0 10 0ZM10 2.99012C11.8268 2.99012 13.3076 4.47134 13.3076 6.29783C13.3076 8.12475 11.8268 9.60553 10 9.60553C8.17359 9.60553 6.69288 8.12475 6.69288 6.29783C6.69288 4.47134 8.17359 2.99012 10 2.99012ZM9.9978 17.3855C8.17535 17.3855 6.50619 16.7217 5.21875 15.6231C4.90512 15.3556 4.72415 14.9638 4.72415 14.5513C4.72415 12.6993 6.22332 11.2168 8.07608 11.2168H11.9252C13.778 11.2168 15.2719 12.6993 15.2719 14.5513C15.2719 14.9638 15.0914 15.3552 14.7773 15.6227C13.4903 16.7217 11.8207 17.3855 9.9978 17.3855Z" fill="#0063BD"/>
      </svg>

  );
}

export default SvgPersonalPageIpadSmall;
