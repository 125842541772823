import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route ,Redirect} from "react-router-dom";
import Header from "./shared/components/header";
import Footer from "./shared/components/footer";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Verification from "./pages/verify/verify";
const ResetPassword = lazy(() =>import("./pages/personalPages/resetPassword/index"));
const HomePage = lazy(() => import("./pages/homepage/index"));
const CategoryPage = lazy(() => import("./pages/categoryPage/index"));
const Product = lazy(() => import("./pages/product/Product"));
const Basket = lazy(() => import("./pages/basketPage/basket"));
const Delivery = lazy(() => import("./pages/basketPage/delivery"));
const Result = lazy(() => import("./pages/result/result"));
const PersonalPgaes = lazy(() => import("./pages/personalPages"));
const AboutUs = lazy(() => import("./pages/infoPages/AboutUs/index"));
const AskQuestion = lazy(() => import("./pages/infoPages/askQuestion/index"));
const Contact = lazy(() => import("./pages/contact"));
const PaymentPage = lazy(() => import("./pages/paymentPage"));
const ShipmentPage = lazy(() => import("./pages/shipmentPage"));
const Conditions = lazy(() => import("./pages/conditions"));

const Routes = () => {
  const locale = useSelector((state) => state.language.locale);
  const translations = useSelector((state) => state.infoData.translations);

  return (
    <BrowserRouter>
      <Helmet title={translations["SITE_TITLE"][locale]} />
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route
            path={[
              "/category/:Section/:subSection/:key",
              "/category/:Section/:subSection",
              "/category/:Section",
            ]}
            component={CategoryPage}
          />
          <Route path="/product-search/:search" component={CategoryPage} />
          <Route path="/product/:key" exact component={Product} />
          <Route path="/basket" exact component={Basket} />
          <Route path="/delivery" exact component={Delivery} />
          <Route path="/result" exact component={Result} />
          <Route path="/personalPgaes" component={PersonalPgaes} />
          <Route path="/email/verify/:id" component={Verification} />
          <Route path="/what-is-postmarket" exact component={AboutUs} />
          <Route path="/frequently-asked-questions" exact component={AskQuestion} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/payment-method" exact component={PaymentPage} />
          <Route path="/shipping" exact component={ShipmentPage} />
          <Route path="/terms-and-conditions" exact component={Conditions} />
          <Route path="/reset-password" exact component={ResetPassword} />
            <Redirect from='*' to='/' />
        </Switch>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;