import Cookies from 'js-cookie'
import toast from "react-hot-toast";

const renderHeaders = (header, action) => {
    let newHead = !(action.meta.config.body && action.meta.config.body.file) ?
        {
            ...header,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        } :
        {
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }
    if (Cookies.get('authorized')) {
        return {
            ...newHead,
            Authorization: `Bearer ${Cookies.get('authorized')}`
        }
    } else {
        return {
            ...newHead
        }
    }
}
export const apiCallMiddleware = store => next => action => {
    let lang = localStorage.getItem('lang') || 'hy';
    return new Promise(async function (resolve, reject) {
        if (action.params && action.params.disableAPICall) {
            await next({
                type: action.type,
                payload: action.payload,
            });
            resolve();
        } else {
            try {
                if (!action.type) {
                    throw new Error("Please add Valid action type")
                }
                const newHeaders = renderHeaders(action.meta.config.headers, action)
                const newConfig = {
                    ...action,
                    meta: {
                        ...action.meta,
                        config: {
                            ...action.meta.config,
                            headers: newHeaders,
                            body: action.meta.config.body &&
                            action.meta.config.body.file ? action.meta.config.body.data : JSON.stringify(action.meta.config.body)
                        }
                    }
                }
                let addistionalQuery = ''
                if (action.meta.url.includes("?")) {
                    addistionalQuery = `&locale=${lang}`
                } else {
                    addistionalQuery = `?locale=${lang}`
                }
                let url = !(action.meta.config.body && action.meta.config.body.file) ?
                    action.meta.url + addistionalQuery :
                         action.meta.url

                const response = await fetch(url, newConfig.meta.config)

                if (response.ok) {
                    if (action.payload) {
                        next({
                            type: action.type,
                            payload: action.payload
                        });
                        resolve();
                    } else {
                        if (action.params && action.params.noResponse) {
                            resolve();
                        } else {
                            try {
                                const result = await response.json();
                                next({
                                    type: action.type,
                                    payload: result
                                });
                                resolve(result);
                            } finally {
                                resolve();
                            }
                        }
                    }

                } else {
                    switch (response.status) {
                        case 404: {
                            const result = await response.json();
                            reject(result);
                            break;
                        }
                        case 400: {
                            const result = await response.json();
                            console.log("result",result.message)
                            toast.error(result.message)
                            break;
                        }
                        default:
                            const result = await response.json();
                            reject(result);
                            return false;
                    }
                }
            } catch (error) {
                console.log(error)
                reject();
            }
        }
    });
}