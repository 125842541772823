import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { useWindowSize } from '@reach/window-size';
import MuList from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import BorgerMenu from '../../../../resources/icons/BorgerMenu';
import HaypostLogoPNG from '../../../../assets/images/haypostLogo.png';
import CancelVector from '../../../../resources/icons/CancelVector';
import Search from '../../../../resources/icons/Search';
import PersonalPageIpadSmall from '../../../../resources/icons/PersonalPageIpadSmall';
import LoginSmall from '../../../../resources/icons/LoginSmall';
import HeartIpadSmall from '../../../../resources/icons/HeartIpadSmall';
import BasketSmall from '../../../../resources/icons/BasketSmall';
import SearchIpadSmall from '../../../../resources/icons/SearchIpadSmall';
import RemoveIpadSmall from '../../../../resources/icons/RemoveIpadSmall';
import PopUp from '../../../ui/popup/index';
import loginRes from '../../../../assets/images/loginRes.png';
import Signin from '../../sigin/index';
import CancelBoldVector from '../../../../resources/icons/CancelBoldVector'
import ArrowDown from "../../../../resources/icons/ArrowDown"
import { handleLangChange, isNotNull } from '../../helpers/utils';
import './style.scss';
import { Accordion, AccordionDetails, AccordionSummary, Dialog } from '@material-ui/core';
import { ResultDone } from '../../../../resources/icons';
import SvgArrowDown from '../../../../resources/icons/ArrowDown';
import MyOrdersIpadSmall from "../../../../resources/icons/MyOrdersIpadSmall";


export default function HeaderContactIpad() {
    const coockiesData = useSelector(state => state.authData.userAuth?.access_token);
    const category = useSelector(state => state.productData.categories);
    const translations = useSelector(state => state.infoData.translations)
    const cartItemsCount = useSelector(state => state.cartData.cartItemsCount)
    const wishlistCount = useSelector(state => state.userData.wishlistCount);
    const headerPages = useSelector(state => state.infoData.headerPages);
    const locale = useSelector(state => state.language.locale)
    const history = useHistory()
    const dispatch = useDispatch()
    const { width } = useWindowSize();
    const [language, setLanguage] = useState('');
    const [activePopUp, setActivePopUp] = useState(false)
    const [Visible, setVisible] = useState(true);
    const [state, setState] = useState({ left: false });
    const [searchValue, setSearchValue] = useState('');
    const [registerSuccessPopUp, setRegisterSuccessPopUp] = useState(false);
    const [activeCategory, setActiveCategory] = useState({});

    useEffect(() => {
        setLanguage(locale || 'hy')
    }, [locale])

    useEffect(() => {
        if (coockiesData) {
            setActivePopUp(false)
        }

    }, [coockiesData])

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
        handleLangChange(event.target.value, dispatch)
    };
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        setActiveCategory([])
    };
    const categoryHandler = () => {
        toggleDrawer('left', false)('Tab')
    }

    const list = (anchor) => (
        <div
            className='list-area'
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <MuList>
                <div className="category-links-field">
                    <div className="categoryWrapper">
                        {!isNotNull(activeCategory) ? <>
                            <div className='cancale-bold-vector'>
                                <p>{translations['CATEGORIES'][language]}</p>
                                <CancelBoldVector onClick={toggleDrawer('left', false)} />
                            </div>
                            {
                                category.map((item, index) => (
                                    item.children.length ? <div
                                        onClick={() => {
                                            setActiveCategory(item);
                                        }}
                                        className="categoryItem"
                                        key={index}
                                    >
                                        <div to={"/category/" + item.slug} className="header-vector-title">
                                            <div className="categoryItemImg">
                                                <img src={item.icon} />
                                            </div>
                                            <p>{item.name}</p>
                                            <SvgArrowDown/>
                                        </div>
                                    </div> : <div onClick={categoryHandler} className="categoryItem" key={index}>
                                        <Link
                                            to={"/category/" + item.slug}
                                            className="header-vector-title"
                                        >
                                            <div className="categoryItemImg">
                                                <img src={item.icon} />
                                            </div>
                                            <p>{item.name}</p>
                                            <SvgArrowDown/>
                                        </Link>
                                    </div>

                                ))


                            }
                        </> : <>
                            <div
                                onClick={() => { setActiveCategory([]) }}
                                className='cancale-bold-vector gray'>
                                <div>
                                    <SvgArrowDown style={{ transform: "rotate(90deg)" }} />
                                    <p>{translations['CATEGORIES'][language]}</p>
                                </div>
                                <CancelBoldVector onClick={toggleDrawer('left', false)} />
                            </div>
                            <div
                                className="categoryItem"
                            >
                                <div to={"/category/" + activeCategory.slug} className="header-vector-title">
                                    <div className="categoryItemImg">
                                        <img src={activeCategory.icon} />
                                    </div>
                                    <p style={{ color: "#0063BD" }}>{activeCategory.name}</p>
                                </div>
                            </div>
                            {
                                activeCategory.children.map((el, ie) => (
                                    el.children.length ? <Accordion>
                                        <AccordionSummary>
                                            <div className="categoryItem recoursive" key={ie}>
                                                <div
                                                    to={"/category/" + el.slug}
                                                    className="header-vector-title"
                                                >
                                                    <div className="categoryItemImg">
                                                        <img src={el.icon} />
                                                    </div>
                                                    <p>{el.name}</p>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                el.children.map((e, i) => (
                                                    <div onClick={categoryHandler} className="categoryItem" key={i}>
                                                        <Link
                                                            to={"/category/" + e.slug}
                                                            className="header-vector-title"
                                                        >
                                                            <div className="categoryItemImg">
                                                                <img src={e.icon} />
                                                            </div>
                                                            <p>{e.name}</p>
                                                        </Link>
                                                    </div>
                                                ))
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                        :
                                        <div onClick={categoryHandler} className="categoryItem recoursive" key={ie}>
                                            <Link
                                                to={"/category/" + el.slug}
                                                className="header-vector-title"
                                            >
                                                <div className="categoryItemImg">
                                                    <img src={el.icon} />
                                                </div>
                                                <p>{el.name}</p>
                                            </Link>
                                        </div>
                                ))
                            }


                        </>
                        }
                    </div>
                </div>
                <div className="header-dropdown-ipad">
                    <FormControl className="lang-select"
                    >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={language}
                            IconComponent={ArrowDown}
                            className="lang-dropdown"
                            onChange={handleChangeLanguage}
                        >
                            <MenuItem value={"hy"}>հայերեն </MenuItem>
                            <MenuItem value={"en"}>english</MenuItem>
                            <MenuItem value={"ru"}>русский</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="header-contact-info-ipad">
                        {
                            isNotNull(headerPages) && headerPages.data.map((item, index) => (
                                <Link
                                    key={index}
                                    to={item.slug}
                                    onClick={categoryHandler}
                                >
                                    {item.title}
                                </Link>
                            ))
                        }
                        <p>{translations['HEADER_CONTACT_INFO'][language]} {translations['HEADER_PHONE'][language]}</p>
                    </div>
                </div>
            </MuList >
        </div >
    );

    function hideMe() {
        setVisible(prev => !prev);
    }

    const backHomePageHandler = () => {
        history.push('/');
    }
    const activePopUpHandler = () => {
        setActivePopUp(true)
    }
    const pasivePopUpHandler = () => {
        setActivePopUp(false)
    }
    const closeRegisterSuccessPopUp = () => {
        setRegisterSuccessPopUp(false)
    }
    const toBasketPageHandler = () => {
        history.push('/basket')
    }
    const toMyOrders = () => {
        history.push('/personalPgaes/component-order')
    }
    const toPersonalPageHandler = () => {
        history.push('/personalPgaes')
    }
    const toMyFavoritesHandler = () => {
        history.push('/PersonalPgaes/my-product')
    }

    return (
        <React.Fragment >
            <div onClose={toggleDrawer('left', false)} className="header-contact-ipad">
                <div className="header-ipad">
                    <BorgerMenu onClick={toggleDrawer('left', true)} />
                    {(['left'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    )))}
                    <img src={HaypostLogoPNG} onClick={backHomePageHandler} className={width < 768 && !Visible ? 'contact-search-remove' : ''} />
                </div>
                <div className={Visible ? ' topnav' : 'header-menu-list-item-tablet'}>
                    <div className="search-container-ipad">
                        <input className="header-input-ipad" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="text" placeholder={translations['SEARCHING'][language]} name="search" />
                        <div className="search-icon" onClick={() => history.push('/product-search/' + searchValue)}>
                            <Search />
                        </div>
                    </div>
                </div>
                <div className={!Visible ? ' topnav' : 'header-menu-list-item'}>
                </div>
                <div className="contact-icons-ipad">
                    <div className={Visible ? 'contact-search' : 'contact-search-remove'} onClick={hideMe}>
                        <SearchIpadSmall />
                    </div>
                    <div className={!Visible ? 'contact-search ' : 'contact-search-remove'} onClick={hideMe}>
                        <RemoveIpadSmall />
                    </div>
                    {!coockiesData ? <div onClick={activePopUpHandler} className={width < 768 && !Visible ? 'contact-search-remove' : 'contact-baskrt-icon'}>
                        <LoginSmall />
                    </div> :
                        <React.Fragment>
                            {/*<div onClick={toMyFavoritesHandler} className={width < 768 ? !Visible ? 'contact-search-remove' : 'contact-basket-icon' : 'contact-basket-icon'} style={{ position: "relative" }}>*/}
                            {/*    <HeartIpadSmall />*/}
                            {/*    <div className="contact-basket-quantity" style={{ top: "-3px", right: "0px" }}>{wishlistCount}</div>*/}
                            {/*</div>*/}
                            <div onClick={toPersonalPageHandler} className={width < 768 ? !Visible ? 'contact-search-remove' : 'contact-basket-icon' : ""}>
                                <PersonalPageIpadSmall />
                            </div>
                            <div onClick={toMyOrders} className={width < 768 ? !Visible ? 'contact-search-remove' : 'contact-basket-icon' : ""}>
                                <MyOrdersIpadSmall />
                            </div>
                        </React.Fragment>
                    }
                    <div id="basket-icon" onClick={toBasketPageHandler} className={width < 768 ? !Visible ? 'contact-search-remove' : 'contact-basket-icon' : 'contact-basket-icon'}>
                        <BasketSmall style={{ paddingRight: 0 }} />
                        <div className="contact-basket-quantity">{cartItemsCount}</div>
                    </div>
                </div>

            </div>
            <PopUp onClose={pasivePopUpHandler} active={activePopUp}>
                <CancelVector onClick={pasivePopUpHandler} className='sign-popup-cancale-icon' />
                <img src={loginRes} />
                <Signin
                    active={activePopUp}
                    onClosePopup={() => setActivePopUp(false)}
                    setRegisterSuccessPopUp={setRegisterSuccessPopUp}
                />
            </PopUp>

            <Dialog
                className="register-sucsses-popUp-mobile"
                onClose={closeRegisterSuccessPopUp}
                open={registerSuccessPopUp}
            >
                <div className='register-sucsses-content'>
                    <ResultDone />
                    <p>Ստուգեք Ձեր էլ. հասցեն</p>
                    <span
                        onClick={closeRegisterSuccessPopUp}
                    >Օքեյ</span>
                </div>
            </Dialog>
        </React.Fragment >

    )
}