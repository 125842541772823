import * as React from "react";

function SvgToshopcard(props) {
  return (
    <svg
      width={34}
      height={34}
      fill="none"
      viewBox = "0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={17} r={17} fill='#fff' />
      <path d="M23 21.5h-8.5L11.552 12H26l-3 9.5z" fill={props.checkedshop} />
      <path
        d="M7 7h3l1.552 5m0 0l2.948 9.5H23l3-9.5H11.552z"
        stroke="#0063BD"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={15.5} cy={25.5} r={1.5} fill="#0063BD" />
      <circle cx={21.5} cy={25.5} r={1.5} fill="#0063BD" />
    </svg>
  );
}

export default SvgToshopcard;
