import * as React from "react";

function SvgArrowDown(props) {
    return (
        <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M1 1L6.5 5L12 1" stroke="#0063BD" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}

export default SvgArrowDown;
