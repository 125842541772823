import React, {useState} from 'react'
import { useSelector} from 'react-redux';
import {Link} from "react-router-dom";
import Facebook from '../../../resources/icons/Facebook';
import Instagram from '../../../resources/icons/Instagram';
import {isNotNull} from '../helpers/utils';
import PopUp from "../../ui/popup";
import "./style.scss"
import BecomeAPartner from "./BecomeAPartner"
import ProductRequest from "./ProductRequest";

export default function Footer() {
    const footersData = useSelector(state => state?.infoData?.footer);
    const translations = useSelector(state => state.infoData.translations)
    const locale = useSelector(state => state.language.locale)
    const loading = useSelector(state => state.loading.loading)
    const [activePopUp, setActivePopUp] = useState(false);
    const [reqPopUp, setReqPopUp] = useState(false);

    const pasivePopUpHandler = () => {
        setActivePopUp(false)
    }

    return (
        <div>
            <div className="container-footer">
                <div className="footer-inner">
                    {isNotNull(footersData) ? Object.keys(footersData).map((block, index) => {
                        return (
                            <div key={index}
                                 className={`footer-container`}>
                                <p className='foooter-container-title'>{translations[block]?.[locale]}</p>
                                {footersData[block].map((item, i) => (
                                    <Link
                                        to={"/" + item.slug}
                                        key={i}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                        }}
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                                {index === 0 && <span
                                    onClick={() => setActivePopUp(true)}>{translations["BECOME_A_PARTNER"]?.[locale]}</span>
                                }
                                {index === 1 && <span
                                    onClick={() => setReqPopUp(true)}>{translations["PRODUCT_REQUEST"]?.[locale]}</span>
                                }
                            </div>
                        )
                    }) : null
                    }
                    <div className="footer-block">
                        <a href="https://www.facebook.com/PostMarket.am/" target="_blank" className='footer-logo'>
                            <Facebook/>
                        </a>
                        <a href="https://www.instagram.com/haypost.market/" target="_blank" className='footer-logo'>
                            <Instagram style={{width: "25.63px", height: "25.63px"}}/>
                        </a>
                    </div>
                </div>
                <p className="text-footer">{translations["COPYRIGHT"]?.[locale]}</p>
                <div className="#E40046-line"></div>
                <PopUp onClose={pasivePopUpHandler} active={activePopUp} className="become-a-partner-popUp">
                    <BecomeAPartner
                        loading={loading}
                        translations={translations}
                        pasivePopUpHandler={pasivePopUpHandler}
                        locale={locale}
                    />
                </PopUp>
                <PopUp
                    onClose={() => {
                        setReqPopUp(false)
                    }}
                    active={reqPopUp}
                    className="product-req-popup"
                >
                    <ProductRequest
                        translations={translations}
                        locale={locale}
                        setReqPopUp={setReqPopUp}
                    />
                </PopUp>
            </div>
        </div>
    )
}





