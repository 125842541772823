import * as React from "react";

function GoogleLogo() {
    return (
        <svg width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"    >
            <path d="M15.8445 6.93323H15.2001V6.90003H8.00003V10.1H12.5213C11.8617 11.9628 10.0892 13.3001 8.00003 13.3001C5.34922 13.3001 3.20001 11.1508 3.20001 8.50003C3.20001 5.84922 5.34922 3.70001 8.00003 3.70001C9.22364 3.70001 10.3368 4.16162 11.1844 4.91562L13.4473 2.65281C12.0185 1.3212 10.1072 0.5 8.00003 0.5C3.58202 0.5 0 4.08202 0 8.50003C0 12.9181 3.58202 16.5001 8.00003 16.5001C12.4181 16.5001 16.0001 12.9181 16.0001 8.50003C16.0001 7.96363 15.9449 7.44003 15.8445 6.93323Z" fill="#FFC107" />
            <path d="M0.921875 4.77642L3.55029 6.70403C4.26149 4.94322 5.9839 3.70001 7.99951 3.70001C9.22311 3.70001 10.3363 4.16162 11.1839 4.91562L13.4467 2.65281C12.0179 1.3212 10.1067 0.5 7.99951 0.5C4.92669 0.5 2.26188 2.23481 0.921875 4.77642Z" fill="#FF3D00" />
            <path d="M7.99996 16.5003C10.0664 16.5003 11.944 15.7095 13.3636 14.4235L10.8876 12.3283C10.0844 12.9367 9.08596 13.3003 7.99996 13.3003C5.91915 13.3003 4.15234 11.9735 3.48674 10.1219L0.87793 12.1319C2.20194 14.7227 4.89075 16.5003 7.99996 16.5003Z" fill="#4CAF50" />
            <path d="M15.8444 6.93322H15.2V6.90002H8V10.1H12.5212C12.2044 10.9948 11.6288 11.7664 10.8864 12.3284C10.8868 12.328 10.8872 12.328 10.8876 12.3276L13.3636 14.4229C13.1884 14.5821 16 12.5 16 8.50003C16 7.96363 15.9448 7.44003 15.8444 6.93322Z" fill="#1976D2" />
        </svg>
    );
}

export default GoogleLogo;