import * as React from "react";

function SvgExit(props) {
  return (
    <svg
      width={19}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.48 11.472h8.799a.73.73 0 01.005 1.459h-.005l-8.804-.006 1.842 1.848a.73.73 0 010 1.03.724.724 0 01-1.027 0L.213 12.718a.73.73 0 010-1.031L3.29 8.598a.724.724 0 011.027 0 .73.73 0 010 1.031L2.48 11.472z"
        fill={props.fill}
      />
      <path
        d="M14.258 25H7.748a.878.878 0 01-.874-.877c0-.478.393-.872.874-.872h6.509a2.999 2.999 0 002.989-3V4.75c0-1.656-1.345-3-2.99-3H7.86a.878.878 0 01-.875-.877c0-.477.394-.872.875-.872h6.404c2.606 0 4.738 2.133 4.738 4.754v15.492C18.995 22.866 16.869 25 14.257 25z"
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgExit;
