import * as React from "react";

function SvgStepsEllipse2(props) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={props.opacity}>
        <circle cx={18} cy={18} r={18} fill="#0063BD" />
        <path
          d="M20.89 23h-6.234v-1.066l2.393-2.605c.342-.369.647-.706.916-1.012.269-.305.497-.604.683-.895.192-.292.338-.584.438-.875.1-.296.15-.618.15-.964 0-.269-.038-.506-.116-.71a1.402 1.402 0 00-.335-.52 1.35 1.35 0 00-.506-.329 1.87 1.87 0 00-.656-.109c-.433 0-.825.089-1.176.267a4.74 4.74 0 00-.99.67l-.698-.814c.182-.164.378-.317.588-.458.21-.146.435-.271.676-.376.242-.105.497-.187.766-.246a3.66 3.66 0 01.848-.096c.437 0 .831.062 1.182.185.356.123.657.3.903.533.246.228.435.508.567.84.137.329.205.7.205 1.115 0 .387-.061.757-.184 1.108-.119.35-.285.697-.5 1.039-.21.337-.462.676-.758 1.018-.292.342-.609.698-.95 1.067L16.18 21.81v.054h4.71V23z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgStepsEllipse2;
