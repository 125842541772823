import * as React from "react";

function SvgCancelVector(props) {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.582 10.998l7.876-7.858a1.84 1.84 0 00-2.6-2.601L11 8.416 3.143.539a1.839 1.839 0 10-2.601 2.6L8.418 11 .542 18.856a1.832 1.832 0 000 2.601 1.832 1.832 0 002.6 0L11 13.581l7.857 7.877a1.832 1.832 0 002.601 0 1.828 1.828 0 00.401-2.005 1.83 1.83 0 00-.4-.596l-7.877-7.859z"
        fill="#F5F5F5"
      />
    </svg>
  );
}

export default SvgCancelVector;
