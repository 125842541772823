import React from 'react';

function svgMyOrders(props) {
    return (
        <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17158 1.17158C3.57628e-07 2.34314 0 4.22876 0 8V26C0 29.7712 3.57628e-07 31.6568 1.17158 32.8284C2.34314 34 4.22876 34 8 34H20C23.7712 34 25.6568 34 26.8284 32.8284C28 31.6568 28 29.7712 28 26V8C28 4.22876 28 2.34314 26.8284 1.17158C25.6568 3.57628e-07 23.7712 0 20 0H8C4.22876 0 2.34314 3.57628e-07 1.17158 1.17158ZM8 8C6.89544 8 6 8.89544 6 10C6 11.1046 6.89544 12 8 12H20C21.1046 12 22 11.1046 22 10C22 8.89544 21.1046 8 20 8H8ZM8 16C6.89544 16 6 16.8954 6 18C6 19.1046 6.89544 20 8 20H20C21.1046 20 22 19.1046 22 18C22 16.8954 21.1046 16 20 16H8ZM8 24C6.89544 24 6 24.8954 6 26C6 27.1046 6.89544 28 8 28H16C17.1046 28 18 27.1046 18 26C18 24.8954 17.1046 24 16 24H8Z" fill="#0063BD"/>
        </svg>

    );
}

export default svgMyOrders;