import { SET_LOADING } from "../actions/types";

const initialState = {
    loading: false
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_LOADING:
            return {
                loading: payload
            };
            
        default:
            return state;
    }
}