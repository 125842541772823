import { combineReducers } from 'redux';
import productData from './productData'
import authData from './authData'
import infoData from './infoData'
import userData from './userData'
import banners from './banners'
import loading from './loading'
import language from './language';
import postBranchData from './postBranchData'
import cartData from "./cartData"
import pageData from "./pagesData"

export default combineReducers({
    productData,
    authData,
    infoData,
    userData,
    banners,
    loading,
    language,
    postBranchData,
    cartData,
    pageData
});