import { SET_LOADING } from './types';

export const setLoading = (res) => {
    
    return {
        params:{
            disableAPICall: true,
        },
        type: SET_LOADING,
        payload:res
    }
}