import * as React from "react";

function SvgCarbonBox(props) {
  return (
    <svg
      width={25}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#carbon_box_svg__clip0)" fill={props.fill}>
        <path d="M16.073 15.179H8.93a1.786 1.786 0 01-1.785-1.786v-1.786A1.785 1.785 0 018.93 9.821h7.143a1.786 1.786 0 011.786 1.786v1.786a1.785 1.785 0 01-1.786 1.786zM8.93 11.607v1.786h7.143v-1.786H8.93z" />
        <path d="M23.214 0H1.786A1.786 1.786 0 000 1.786v3.571a1.786 1.786 0 001.786 1.786v14.286a1.786 1.786 0 001.785 1.785H21.43a1.786 1.786 0 001.785-1.785V7.143A1.786 1.786 0 0025 5.357V1.786A1.786 1.786 0 0023.214 0zM21.43 21.429H3.57V7.143H21.43v14.286zm1.785-16.072H1.786V1.786h21.428v3.571z" />
      </g>
      <defs>
        <clipPath id="carbon_box_svg__clip0">
          <path fill="#fff" d="M0 0h25v23.214H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCarbonBox;
