import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.71.247a8.216 8.216 0 016.547 13.18l6.194 6.195a1.12 1.12 0 01-1.457 1.692l-.126-.109-6.195-6.195a8.214 8.214 0 01-12.75-3.929A8.216 8.216 0 018.709.247zm0 2.24a5.975 5.975 0 100 11.951 5.975 5.975 0 000-11.95z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgSearch;
