import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router";
import {getVerifyData} from "../../redux/actions/authData";

export default function Verification() {
    const {id, hash} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const urlParams = new URLSearchParams(history.location.search)

    const params = {
        expires: urlParams.get('expires'),
        signature: urlParams.get('signature'),
        id,
        hash
    }

    useEffect(() => {
        dispatch(getVerifyData(params)).then(res => {
            history.push("/");
            document.querySelector(".contact-login").click()
        })
            .catch(() => {
                history.push("/")
            })
    }, [])

    return (
        <div
            style={{height: "700px"}}
        >
        </div>
    )
}