import * as React from "react";

function SvgMinus(props) {
  return (
    <svg
      width={18}
      height={2}
      fill="none"
      viewBox="0 0 18 2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1h16"
        stroke="#5F6D7C"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgMinus;
