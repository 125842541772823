import {
    ADD_TO_WISHLIST,
    CHANGE_USER_DETAILS,
    CHANGE_USER_PASSWORD,
    GET_USER_DETAILS,
    GET_WISHLIST,
    CREATE_USER_ADDRESS,
    EDIT_USER_ADDRESS,
    REMOVE_USER_ADDRESS,
    SET_DEFAULT_USER_DETAILS,
    SET_POST_BRANCH,
    SET_DEFAULT_POST_BRANCH,
    DELETE_POST_BRANCH,
    EDIT_POST_BRANCH,
    USER_LOGOUT,
    GET_ORDERS,
    RESET_PASSWORD, FILTER_ORDERS
} from "../actions/types";

const initialState = {
    details: [],
    passwordMassage: '',
    wishlist: null,
    wishlistCount: 0,
    addToWishlist: null,
    default_region: '',
    orderHistory: [],
    defaultLang: '',
    resetPassword : {}
};


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_USER_DETAILS:
        case CHANGE_USER_DETAILS:
        case CREATE_USER_ADDRESS:
        case EDIT_USER_ADDRESS:
        case REMOVE_USER_ADDRESS:
        case SET_POST_BRANCH:
        case SET_DEFAULT_POST_BRANCH:
        case DELETE_POST_BRANCH:
        case EDIT_POST_BRANCH:
        case SET_DEFAULT_USER_DETAILS:
            let region = ''
            payload.data?.addresses.forEach((item) => { if (item.is_default) { region = item.region } })
            return {
                ...state,
                details: payload,
                default_region: region,
                defaultLang: payload.data.default_lang
            };
        case CHANGE_USER_PASSWORD:
            return {
                ...state,
                passwordMassage: payload
            };
        case GET_WISHLIST:
            return {
                ...state,
                wishlist: payload.data,
                wishlistCount: payload.data.length
            };
        case ADD_TO_WISHLIST:
            return {
                ...state,
                addToWishlist: payload
            };
        case USER_LOGOUT:
            return {
                initialState
            };
        case GET_ORDERS:
        case FILTER_ORDERS:
            return {
                ...state,
                orderHistory: payload
            }
        case RESET_PASSWORD:
            return{
                ...state,
            }
        default:
            return state;
    }
}