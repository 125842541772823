import { GET_REGIONS, RESET_POST_BRANCHES, GET_CITIES_BY_REGIONS, GET_ADDRESS_BY_CITY, GET_POST_BRANCH_BY_ADDRESS, GET_POST_BRANCH_BY_INDEX, RESET_ALL_BRANCHES} from "../actions/types"
import { isNotNull } from "../../shared/components/helpers/utils"

const initialState = {
    regions: [],
    cities: [],
    addresses: [],
    postBranches: [],
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case GET_REGIONS:
            return {
                ...state,
                regions: payload.regions
            };
        case GET_CITIES_BY_REGIONS:
            return {
                ...state,
                cities: payload.cities
            };
        case GET_ADDRESS_BY_CITY:
            return {
                ...state,
                addresses: payload.address
            };
        case GET_POST_BRANCH_BY_ADDRESS:
            let branches = []
            if (isNotNull(payload.branches)) {
                Object.keys(payload.branches).forEach(key => branches.push(payload.branches[key]))
            }
            return {
                ...state,
                postBranches: branches
            }
        case GET_POST_BRANCH_BY_INDEX:
            return {
                ...state,
                postBranches: payload.branches
            }
        case RESET_ALL_BRANCHES:
            return {
                ...state,
                postBranches: [],
                addresses: [],
                cities: [],
                regions: []
            }
        case RESET_POST_BRANCHES:
            return {
                ...state,
                postBranches: []
            }
        default:
            return state;
    }
}