import * as React from "react";

function SvgCardsIcon(props) {
  return (
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.12891 2.44697C1.12891 2.17974 1.23291 1.92346 1.41804 1.7345C1.60318 1.54555 1.85427 1.43939 2.11609 1.43939H19.8853C20.1471 1.43939 20.3982 1.54555 20.5834 1.7345C20.7685 1.92346 20.8725 2.17974 20.8725 2.44697V16.553C20.8725 16.8203 20.7685 17.0765 20.5834 17.2655C20.3982 17.4544 20.1471 17.5606 19.8853 17.5606H2.11609C1.85427 17.5606 1.60318 17.4544 1.41804 17.2655C1.23291 17.0765 1.12891 16.8203 1.12891 16.553V2.44697Z" stroke="#5F6D7C" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M2 6L20 6" stroke="#5F6D7C" strokeWidth="2" strokeLinejoin="square" strokeLinejoin="round"/>
    <path d="M12.4805 13.5303H16.9228" stroke="#5F6D7C" strokeWidth="1.5" strokeLinejoin="round" strokeLinejoin="round"/>
    <path d="M20.873 2.44696V10.5076" stroke="#5F6D7C" strokeWidth="1.5" strokeLinejoin="round" strokeLinejoin="round"/>
    <path d="M1.12891 2.44696V10.5076" stroke="#5F6D7C" strokeWidth="1.5" strokeLinejoin="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default SvgCardsIcon;
