import { USER_AUTH, USER_LOGOUT, USER_PASS_FORGOT, USER_REGISTER, AUTHORIZE, USER_PASS_RESET, USER_AUTH_GOOGLE, USER_LOGOUT_LOCAL, GET_VERIFY_DATA, AUTH_GOOGLE_LOGIN, AUTH_FB_LOGIN } from '../actions/types'

const authState = {
    userAuth: null,
    userAuthGoogle: null,
    userRegister: null,
    userPassForgot: null,
    userPassReset: null,
    verifyData: null,
    authGoogleLogin: null,
    authFbLogin: null
}


export default function (state = authState, { type, payload }) {
    switch (type) {
        case AUTH_GOOGLE_LOGIN:
        case USER_AUTH:
            return {
                ...state,
                userAuth: payload
            };
        case USER_REGISTER:
            return {
                ...state,
                userRegister: payload
            };
        case USER_LOGOUT:
            return {
                userAuth: null
            };
        case USER_LOGOUT_LOCAL:
            return {
                userAuth: payload
            };
        case USER_PASS_FORGOT:

            return {
                ...state,
                userPassForgot: payload
            };
        case USER_PASS_RESET:

            return {
                ...state,
                userPassReset: payload
            };
        case AUTHORIZE:
            return {
                ...state,
                userAuth: {
                    access_token: payload
                }
            }
        case GET_VERIFY_DATA:
            return {
                ...state,
                verifyData: payload
            }
        case AUTH_GOOGLE_LOGIN:
            return {
                ...state,
                authGoogleLogin: payload
            }
        case AUTH_FB_LOGIN:
            return {
                ...state,
                authFbLogin: payload
            }
        default:
            return state;
    }

}