import * as React from "react";

function SvgMyHeart(props) {
  return (
    <svg
      width={35}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="myHeart_svg__a" fill="#fff">
        <path d="M34.545 10.49c0-9.004-10.513-13.506-17.272-8.253C9.763-3.016 0 1.487 0 10.49S15.02 30 17.273 30c2.253 0 17.272-10.505 17.272-19.51z" />
      </mask>
      <path
        d="M17.273 2.237L15.84 4.285l1.51 1.057 1.457-1.131-1.534-1.974zm0 27.763v2.5V30zm1.534-25.79c5.265-4.091 13.238-.457 13.238 6.28h5C37.045-.78 23.991-6.15 15.74.264l3.068 3.948zm13.238 6.28c0 1.582-.674 3.451-1.986 5.498-1.295 2.02-3.087 4.018-5.007 5.803-1.914 1.779-3.89 3.285-5.496 4.333-.806.526-1.484.914-1.988 1.16a4.863 4.863 0 01-.532.228c-.147.05-.03-.012.237-.012v5c.549 0 1.046-.144 1.367-.252a9.71 9.71 0 001.116-.47c.748-.363 1.613-.866 2.532-1.466 1.844-1.204 4.045-2.886 6.168-4.859 2.117-1.967 4.22-4.284 5.812-6.766 1.574-2.455 2.777-5.276 2.777-8.196h-5zM17.273 27.5c.267 0 .383.062.236.012a4.835 4.835 0 01-.531-.229c-.505-.245-1.183-.633-1.988-1.159-1.606-1.048-3.583-2.554-5.497-4.333-1.92-1.785-3.711-3.783-5.006-5.803C3.174 13.941 2.5 12.072 2.5 10.491h-5c0 2.92 1.204 5.741 2.777 8.196 1.592 2.482 3.696 4.799 5.813 6.766 2.122 1.973 4.323 3.655 6.167 4.859.92.6 1.785 1.103 2.533 1.467.372.181.75.345 1.116.469.32.108.818.252 1.367.252v-5zM2.5 10.49c0-3.509 1.86-6.02 4.387-7.212 2.554-1.204 5.92-1.114 8.953 1.007L18.706.188c-4.477-3.13-9.747-3.416-13.953-1.431C.522.753-2.5 4.997-2.5 10.49h5z"
        fill="#5F6D7C"
        mask="url(#myHeart_svg__a)"
      />
    </svg>
  );
}

export default SvgMyHeart;
