import {
    ADD_CART,
    GET_CART,
    DELETE_CART_ITEM,
    EMPTY_CART,
    EDIT_CART_ITEM_QTY,
    EDIT_CART_ITEM,
    APPLY_PROMO_CODE,
    CANCEL_PROMO_CODE,
    CHECKOUT,
    CHECKOUT_SUCCESS,
    CHECK_PRODUCT_IN_CART,
    SEND_EMAIL_VALIDATION, VALIDATE_EMAIL
} from './types';
import { API_URL } from '../../constants'
import Cookies from 'js-cookie';

const cart_token = () =>{
        if(Cookies.get('authorized')){
            return ''
        } else{
            return(
                localStorage.getItem('cartToken') || ''
            )
        }
    }

export const addToCart = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { ...data, cart_token: cart_token() }
    }
    return {
        type: ADD_CART,
        meta: {
            config,
            url: `${API_URL}/api/add-to-cart`,
        },
    }
}


export const
    getCart = ({ region, is_shipping, shipping_type }) => {
    const config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    }
    return {
        type: GET_CART,
        meta: {
            config,
            url: `${API_URL}/api/get-cart?cart_token=${cart_token()}&region=${region || ''}&is_shipping=${is_shipping || ''}&shipping_type=${shipping_type || ''}`,
        },
    }
}
export const checkProductsInCart = () => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { cart_token: cart_token() }
    }
    return {
        type: CHECK_PRODUCT_IN_CART,
        meta: {
            config,
            url: `${API_URL}/api/check-products`,
        },
    }
}

export const deleteCartItem = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { ...data, cart_token: cart_token() }
    }
    return {
        type: DELETE_CART_ITEM,
        meta: {
            config,
            url: `${API_URL}/api/delete-cart-item`,
        },
    }
}
export const emptyCart = () => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { cart_token: cart_token() }
    }
    return {
        type: EMPTY_CART,
        meta: {
            config,
            url: `${API_URL}/api/empty-cart`,
        },
    }
}

export const editCartItemQTY = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { ...data, cart_token: cart_token() }
    }
    return {
        type: EDIT_CART_ITEM_QTY,
        meta: {
            config,
            url: `${API_URL}/api/edit-cart-item-qty`,
        },
    }
}

export const editCartItem = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: { ...data, cart_token: cart_token() }
    }
    return {
        type: EDIT_CART_ITEM,
        meta: {
            config,
            url: `${API_URL}/api/update-cart-item`,
        },
    }
}

export const applyPromoCode = (promocode) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    }
    return {
        type: APPLY_PROMO_CODE,
        meta: {
            config,
            url: `${API_URL}/api/apply-promo-code?cart_token=${cart_token()}&promo_code=${promocode}`,
        },
    }
}

export const cancelPromoCode = () => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    }
    return {
        type: CANCEL_PROMO_CODE,
        meta: {
            config,
            url: `${API_URL}/api/cancel-promo-code?cart_token=${cart_token()}`,
        }
    }
}

export const checkout = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: CHECKOUT,
        meta: {
            config,
            url: `${API_URL}/api/checkout?cart_token=${cart_token()}`,
        },
    }
}

export const sendEmailValidation = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: SEND_EMAIL_VALIDATION,
        meta: {
            config,
            url: `${API_URL}/api/checkout/send-email-validation`,
            body:data
        },
    }
}
export const validateEmailAction = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: VALIDATE_EMAIL,
        meta: {
            config,
            url: `${API_URL}/api/checkout/validate-email`,
            body:data
        },
    }
}

export const checkoutSuccess = (data) => {
    const config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: data

    }
    return {
        type: CHECKOUT_SUCCESS,
        meta: {
            config,
            url: `${API_URL}/api/checkout/success`,
        },
    }
}
