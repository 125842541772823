import * as React from "react";

function SvgPostVector(props) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.57c0 .947.79 1.736 1.736 1.736h6.945c.947 0 1.736-.79 1.736-1.736v-6.944c0-.205.142-.348.347-.348h3.472c.205 0 .347.143.347.348v6.944c0 .947.79 1.736 1.736 1.736h6.945c.947 0 1.736-.79 1.736-1.736V1.736C25 .79 24.21 0 23.264 0H1.736C.79 0 0 .79 0 1.736V22.57zm16.667-.347h6.25V2.083H2.083v20.14h6.25v-6.597c0-1.33 1.101-2.431 2.43-2.431h3.473c1.33 0 2.43 1.1 2.43 2.43v6.598z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgPostVector;
