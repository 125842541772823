import * as React from "react";

function SvgHeart2(props) {
  return (
    <svg
      width={25}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="heart-2_svg__a" fill="#fff">
        <path d="M24.62 7.343c0-6.303-7.493-9.454-12.31-5.777C6.958-2.111 0 1.04 0 7.344 0 13.646 10.705 21 12.31 21c1.606 0 12.31-7.354 12.31-13.657z" />
      </mask>
      <path
        d="M12.31 1.566l-1.132 1.648 1.194.82 1.152-.878-1.214-1.59zm0 19.434v-2 2zm1.214-17.844C17.197.352 22.62 2.91 22.62 7.344h4c0-8.173-9.564-11.918-15.524-7.368l2.427 3.18zm9.097 4.188c0 1.03-.45 2.285-1.373 3.7-.908 1.39-2.17 2.773-3.529 4.014a29.94 29.94 0 01-3.887 3.01c-.57.365-1.045.632-1.394.8a3.31 3.31 0 01-.356.15c-.098.032.008-.018.228-.018v4c.422 0 .799-.108 1.034-.186.27-.09.548-.209.819-.338.543-.26 1.167-.616 1.827-1.04a33.94 33.94 0 004.426-3.424c1.517-1.385 3.032-3.022 4.182-4.782 1.134-1.738 2.023-3.765 2.023-5.887h-4zM12.31 19c.22 0 .325.05.228.018a3.305 3.305 0 01-.356-.15c-.35-.168-.825-.435-1.394-.8a29.947 29.947 0 01-3.888-3.01c-1.359-1.24-2.62-2.624-3.529-4.015C2.45 9.63 2 8.373 2 7.343h-4c0 2.122.889 4.149 2.023 5.887 1.15 1.76 2.664 3.397 4.182 4.782a33.94 33.94 0 004.425 3.424c.66.424 1.285.78 1.828 1.04.27.13.548.248.819.338.235.078.612.186 1.033.186v-4zM2 7.344C2 5.006 3.255 3.33 4.989 2.527c1.758-.815 4.086-.757 6.189.687l2.265-3.297c-3.25-2.232-7.077-2.437-10.136-1.02C.224.328-2 3.379-2 7.345h4z"
        fill={props.fill}
        mask="url(#heart-2_svg__a)"
      />
    </svg>
  );
}

export default SvgHeart2;
