import * as React from "react";

function SvgStepsEllipse1(props) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill="#0063BD" />
      <path
        d="M18.86 23h-1.203v-6.241-.63l.014-.662c.009-.224.016-.435.02-.636.01-.205.016-.385.02-.54-.077.082-.145.153-.204.212-.06.06-.12.116-.185.17l-.191.179c-.069.059-.15.13-.246.211l-1.012.828-.656-.841 2.618-2.044h1.025V23z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgStepsEllipse1;
