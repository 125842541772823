import * as React from "react";

function SvgMenuLine(props) {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.11 20.944H2.888a.722.722 0 110-1.444h20.223a.723.723 0 010 1.444zM23.11 13.722H2.888a.722.722 0 110-1.444h20.223a.722.722 0 010 1.444zM23.11 6.5H2.888a.722.722 0 110-1.444h20.223a.722.722 0 010 1.444z"
        fill="#004494"
      />
    </svg>
  );
}

export default SvgMenuLine;
