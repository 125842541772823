import { SET_LOCALE } from './types';


export const setLocale = (res) => {
    
    return {
        params:{
            disableAPICall: true,
        },
        type: SET_LOCALE,
        payload:res
    }
}