import { BANNER_DATA } from '../actions/types'

const authState = {
    advertisingBanner: [],
    
}


export default function (state = authState, { type, payload }) {
    
    switch (type) {
        case BANNER_DATA:
            return {
                ...state,
                advertisingBanner: payload
            };
        default:
            return state;
    }

}