import {  USER_AUTH, USER_REGISTER, USER_LOGOUT, USER_PASS_FORGOT, AUTHORIZE, USER_PASS_RESET, USER_AUTH_GOOGLE, USER_LOGOUT_LOCAL, GET_VERIFY_DATA, AUTH_GOOGLE_LOGIN, AUTH_FB_LOGIN } from './types'
import { API_URL } from '../../constants'

export const userAuth = (data) => {
   
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            
        },
        body: data
    }
    return {
        type: USER_AUTH,
        meta: {
            config, 
            url: `${API_URL}/api/auth/login`,
        },
    }
}
export const authGoogleLogin = ({code, scope, authuser, prompt}) => {
   
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            
        },
    }
    return {
        type: AUTH_GOOGLE_LOGIN,
        meta: {
            config, 
            url: `${API_URL}/api/auth/google/callback?code=${code}&prompt=${prompt}&scope=${scope}&authuser=${authuser}`,
        },
    }
}
export const authFbLogin = ({code}) => {
   
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: AUTH_GOOGLE_LOGIN,
        meta: {
            config, 
            url: `${API_URL}/api/auth/facebook/callback?code=${code}`,
        },
    }
}


export const userAuthGoogle = () => {
   
    const config = {
        method: "GET",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            withCredentials: true,
            cors: 'no-cors'
        },
        //body: data
    }
    return {
        type: USER_AUTH_GOOGLE,
        meta: {
            config,
            url: `${API_URL}/api/auth/google/login`,
        },
    }
}

export const authorize = (data) => {
    return {
        params:{
            disableAPICall: true,
        },
        type: AUTHORIZE,
        payload: data
    }
}

export const userRegister = (data) => {
   
    const config = {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Methods" : "GET,POST,PUT,DELETE,OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
            
        },
        body: data
    }
    return {
        type: USER_REGISTER,
        meta: {
            config,
            url: `${API_URL}/api/auth/register`,
        },
    }
}

export const userLogout = () => {
     const config = {
         method: "GET",
         headers: {
             "Content-Type": "application/json",
         },
     }
     return {
         type: USER_LOGOUT,
         meta: {
             config,
             url: `${API_URL}/api/auth/logout`,
         },
     }
 }

 export const userLogoutLocal = (props) => {
    
    return {
        params:{
            disableAPICall: true,
        },
        type: USER_LOGOUT_LOCAL,
        payload: null
    }
}

 export const userPassForgot = (data) => {
    
    const config = {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin" : "*",
            "accept": "application/json",
            "Content-Type": "application/json",
            
        },
        body: data
    }
    return {
        type: USER_PASS_FORGOT,
        meta: {
            config,
            url: `${API_URL}/api/auth/forgot-password`,
        },
    }
}

export const userPassReset = (data) => {
  
    const config = {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin" : "*",
            "accept": "application/json",
            "Content-Type": "application/json",
            
        },
        body: data
    }
    return {
        type: USER_PASS_RESET,
        meta: {
            config,
            url: `${API_URL}/api/auth/reset-password`,
        },
    }
}

export const getVerifyData = (params) => {
    const config = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return {
        type: GET_VERIFY_DATA,
        meta: {
            config,
            url: `${API_URL}/api/email/verify/${params.id}?expires=${params.expires}&hash=${params.hash}&signature=${params.signature}`,
        },
    }
}
